import type { AliasToken } from 'antd/es/theme/interface';
import { COLOR } from '../constants';

export const token: Partial<AliasToken> = {
  colorBgBase: COLOR.background.white,
  colorError: COLOR.system.error,
  colorErrorBg: COLOR.background.error,
  colorErrorHover: COLOR.background.error,
  colorInfo: COLOR.system.information,
  colorInfoBg: COLOR.background.information,
  colorPrimary: COLOR.text.primary,
  colorPrimaryBg: COLOR.background.primary,
  colorSuccess: COLOR.system.success,
  colorSuccessBg: COLOR.background.success,
  colorTextBase: COLOR.text.body,
  colorWarning: COLOR.system.warning,
  colorWarningBg: COLOR.background.warning,
  fontFamily: 'Poppins',
};

import React from "react";
import UserSettings from "../components/UserSettings";
import Sidebar from "../components/Sidebar";
import PageTitle from "../components/PageTitle";

const Settings = () => {
  return (
    <Sidebar>
      <PageTitle title="User Settings" />
      <UserSettings />
    </Sidebar>
  );
};

export default Settings;

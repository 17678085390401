import React from 'react'
import { WelcomeImage } from '../utils/images'

const ChatWelcome = () => {
    return (
        <div className="welcome-screen d-flex flex-wrap justify-content-center">
            <div className="welcome-header text-center w-100">
                <img src={WelcomeImage} alt="Welcome" />
            </div>
            <div className="welcome-content w-100">
                <h1 className='mb-0'>Coming Soon 🚀</h1>
                <p className='mb-1'>Send messages to connect with others.</p>
            </div>
        </div>
    )
}

export default ChatWelcome
import React from "react";
import { FaLinkedin } from "react-icons/fa";
import { TbWorldCheck } from "react-icons/tb";

// Shows basic user details at top of profile
const UserDetails = ({ formValues }) => {
  return (
    <div className="profile-details-right ">
      <div
        style={formValues?.isPrivateProfile ? { marginBottom: "90px" } : null}
      >
        <h1 className="profile-heading mb-0">
          {formValues?.firstName
            ? formValues?.firstName + " " + formValues?.lastName
            : formValues?.companyName
              ? formValues?.companyName
              : "..."}
        </h1>

      </div>
      {!formValues?.isPrivateProfile && (
        <>
          <div className="flex-wrap d-flex gap-3 align-items-center mt-2">
            {formValues?.socialLinks?.linkedIn && (
              <a
                href={
                  formValues?.socialLinks?.linkedIn.startsWith("http")
                    ? formValues?.socialLinks?.linkedIn
                    : `http://${formValues?.socialLinks?.linkedIn}`
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin className="social-icons" />
              </a>
            )}

            {formValues?.socialLinks?.websiteUrl && (
              <span className="flex-wrap d-flex gap-1 align-items-center profile-detail-status text-underline">
                <a
                  href={
                    formValues.socialLinks.websiteUrl.startsWith("http")
                      ? formValues.socialLinks.websiteUrl
                      : `http://${formValues.socialLinks.websiteUrl}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TbWorldCheck className="social-icons" />{" "}
                </a>
              </span>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default UserDetails;

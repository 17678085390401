import { jwtDecode } from 'jwt-decode';
import React, { createContext, useState, useEffect, ReactNode } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import { isTokenExpired } from "../helpers";

export const isTokenExpired = (expiration: number) => {
  if (!expiration) {
    return false;
  }
  const currentTimestamp = Math.floor(Date.now() / 1000);
  return currentTimestamp >= expiration;
};

interface RoleContextProps {
  currentRole?: string;
  setCurrentRole: React.Dispatch<React.SetStateAction<string>>;
  isColor?: string;
  setIsColor: React.Dispatch<React.SetStateAction<string>>;
}

interface RoleStateProps {
  children: ReactNode;
}

export const RoleContext = createContext<RoleContextProps>({
  setCurrentRole: () => {},
  setIsColor: () => {},
});

const RoleState: React.FC<RoleStateProps> = (props) => {
  const role = localStorage?.getItem("currentRole");
  const activeTab = localStorage.getItem("activeTab");
  const [currentRole, setCurrentRole] = useState<string>(role ? role : "Investor");
  const [isColor, setIsColor] = useState<string>(activeTab ? activeTab : "home");

  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const location = useLocation();
  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  function parseJwt(token: string | null) {
    if (!token) {
      return null;
    }
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  }

  useEffect(() => {
    const roleString = localStorage.getItem("userRole");
    const roles = roleString ? JSON.parse(roleString) : [];
    const profileFlag = localStorage?.getItem("profileFlag");
    const updatedRole =
      profileFlag === "2" &&
      role === "Startup" &&
      window.location.pathname === "/startup-edit-profile"
        ? "Startup"
        : profileFlag === "2" &&
          role === "Startup" &&
          window.location.pathname !== "/startup-edit-profile"
        ? "Investor"
        : profileFlag === "3" &&
          role === "Investor" &&
          window.location.pathname === "/investor-edit-profile"
        ? "Investor"
        : profileFlag === "3" &&
          role === "Investor" &&
          window.location.pathname !== "/investor-edit-profile"
        ? "Startup"
        : role
        ? role
        : roles?.includes("investor")
        ? "Investor"
        : "Startup";
    setCurrentRole(updatedRole);
    localStorage.setItem("currentRole", updatedRole);
    if (window.location.pathname === "/home") {
      localStorage?.setItem("activeTab", "home");
      setIsColor("home");
    }
    
    if (localStorage.getItem("isRemembered")) {
      navigate("/home");
    }

    const params = new URLSearchParams(location.search);
    if (params && params.size > 0) {
      const googleToken = params.get("token");
      params.delete("token");
      const newSearch = params.toString();
      const newUrl = `${location.pathname}${newSearch ? `?${newSearch}` : ""}`;
      navigate(newUrl);

      if (googleToken) {
        const decoded = parseJwt(googleToken);
        localStorage.setItem("token", googleToken);
        localStorage.setItem("isLoggedIn", "true");
        if (decoded?.user?.role) {
          localStorage.setItem("userRole", JSON.stringify(decoded?.user?.role));
          localStorage.setItem("profileFlag", decoded?.user?.profileFlag);
          localStorage.setItem("user", JSON.stringify(decoded?.user));

          localStorage.setItem(
            "userName",
            decoded?.firstName + " " + decoded?.lastName
          );
          localStorage.setItem("profilePhoto", decoded?.profilePhoto);
          localStorage.setItem("userId", decoded?.user?.id);          
          localStorage.setItem("companyName", decoded?.companyName);
          localStorage.setItem(
            "companyProfilePhoto",
            decoded?.companyProfilePhoto
          );
          setCurrentRole(
            decoded?.user?.role?.includes("investor") ? "Investor" : "Startup"
          );
        }
      }
      const token = localStorage.getItem("token");
      if (token || googleToken) {
        const decodedAuthToken = jwtDecode(token || googleToken || "");
        if (isTokenExpired(decodedAuthToken.exp ?? 0)) {
          handleLogout();
        }
      } else {
        handleLogout();
      }
    } else {
      if (token) {
        const decodedAuthToken = jwtDecode(token);
        if (isTokenExpired(decodedAuthToken.exp ?? 0)) {
          handleLogout();
        }
      } else {
        if (
          window.location.pathname !== "/signup" &&
          window.location.pathname !== "/reset-password" &&
          window.location.pathname !== "/send-email" &&
          window.location.pathname.includes("/verify-email") &&
          window.location.pathname.includes("/update-password")
        ) {
          handleLogout();
        }
      }
    }
   
  }, []);


  return (
    <RoleContext.Provider
      value={{
        currentRole,
        setCurrentRole,
        isColor,
        setIsColor,
      }}
    >
      {props.children}
    </RoleContext.Provider>
  );
};
export default RoleState;

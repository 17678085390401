import React from "react";
import {
  Google,
  Facebook,
  Twitter,
  linkdin,
} from "../../../utils/images/index";
import { Flex } from "antd";
import { Link } from "react-router-dom";
import { config } from "../../../configs";

const SocialLinks: React.FC = () => {
  const handleGoogleLogin = (): void => {
    window.open(`${config?.ApiBaseURL}/auth/google`, "_self");
  };
  return (
    <Flex align="center" gap={20}>
      <Link to="#">
        <img width={64} height={64} src={Google} alt="google " onClick={handleGoogleLogin} />
      </Link>
      <a href="#">
        <img
          width={64}
          height={64}
          src={Facebook}
          alt="facebook"
          onClick={() =>
            window.open(`${config?.ApiBaseURL}/auth/facebook`, "_self")
          }
        />
      </a>
      <a href="#">
        <img
          width={64}
          height={64}
          src={Twitter}
          alt="twitter"
          onClick={() =>
            window.open(`${config?.ApiBaseURL}/auth/twitter`, "_self")
          }
        />
      </a>
      <a href="#">
        <img
          width={64}
          height={64}
          src={linkdin}
          alt="linkdin"
          onClick={() =>
            window.open(`${config?.ApiBaseURL}/auth/linkedin`, "_self")
          }
        />
      </a>
    </Flex>
  );
};

export default SocialLinks;

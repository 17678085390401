import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const PageTitle = ({ title }) => {
  const { pathname } = useLocation();
  // Set page title on mount
  useEffect(() => {
    document.title = !title ? 'Angelflow' : `Angelflow | ${title}`;
    
    // Reset page title on unmount
    return () => {
      document.title = 'Angelflow';
    }
    // Refresh title on title or path change
  }, [title, pathname]);
}

export default PageTitle;
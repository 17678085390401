const signup = "/Signup";
const login = "/";
const updatePassword = "/:token/update-password";
const resetPassword = "/reset-password";
const sendEmail = "/send-email";
const verifyEmail = "/:token/verify-email";
const profileSetup = "/profile-setup";
const home = "/home";
const chat = "/chat";
const startupPublicView = "/startup/:id";
const startupProfileView = "/startup-profile-view";
const startupEditProfile = "/startup-edit-profile";
const investorEditProfile = "/investor-edit-profile";
const investorPublicView = "/investor/:id";
const investorProfileView = "/investor-profile-view";
const investors = "/investors";
const startups = "/startups";
const followers = "/followers";
const userSettings = "/user-settings";
const changePassword = "/change-password";
const postView = "/post/:id";

export {
  signup,
  login,
  resetPassword,
  verifyEmail,
  updatePassword,
  profileSetup,
  home,
  sendEmail,
  chat,
  startupPublicView,
  startupProfileView,
  startupEditProfile,
  investorEditProfile,
  investorPublicView,
  investorProfileView,
  investors,
  startups,
  followers,
  userSettings,
  changePassword,
  postView
};

import APIClient from "../../client";
import { ChangePasswordDto, ForgotPasswordDto, LoginDto, LoginResponse, RegisterDto, RegisterResponse,  SendVerificationEmailDto, UpdatePasswordDto, VerifyOTPDto } from "./Auth.dto";
import { AUTH_ENDPOINTS } from "./endpoints";

const AuthService = {
  login: (data: LoginDto): Promise<LoginResponse> => APIClient.post({ endpoint: AUTH_ENDPOINTS.LOGIN, data, isPublic: true }),
  register: (data: RegisterDto): Promise<RegisterResponse> => APIClient.post({ endpoint: AUTH_ENDPOINTS.REGISTER, data, isPublic: true }),
  sendVerificationEmail: (data: SendVerificationEmailDto) => APIClient.post({ endpoint: AUTH_ENDPOINTS.SEND_VERIFICATION_EMAIL, data, isPublic: true }),
  forgotPassword: (data: ForgotPasswordDto) => APIClient.post({ endpoint: AUTH_ENDPOINTS.FORGOT_PASSWORD, data, isPublic: true }),
  updatePassword: (data: UpdatePasswordDto) => APIClient.post({ endpoint: AUTH_ENDPOINTS.UPDATE_PASSWORD, data }),
  changePassword: (data: ChangePasswordDto) => APIClient.post({ endpoint: AUTH_ENDPOINTS.CHANGE_PASSWORD, data }),
  emailVerification: () => APIClient.post({ endpoint: AUTH_ENDPOINTS.VERIFY_EMAIL }),
  sendOTP: () => APIClient.post({ endpoint: AUTH_ENDPOINTS.SEND_OTP }),
  verifyOTP: (data: VerifyOTPDto) => APIClient.post({ endpoint: AUTH_ENDPOINTS.VERIFY_OTP, data }),
  otpStatus: () => APIClient.get({ endpoint: AUTH_ENDPOINTS.OTP_STATUS }),
};

export default AuthService;

import React from "react";
import { dummyProfile } from "../utils/images";
import { useNavigate } from "react-router-dom";

const ProfileCard = ({ data, type }) => {
  const navigate = useNavigate();
  return (
    <div className="profile-card">
      <div className="view-profile">
        <img
          src={data?.profilePhoto ?? dummyProfile }
          alt="profile-img"
          className="profile-image"
        />
      </div>
      <div className="card-content">
        <h1 className="profile-heading">
          {type === "startup" && data?.companyName
            ? data.companyName.length > 28
              ? data.companyName.slice(0, 28) + "..."
              : data.companyName
            : data?.firstName && data?.lastName
              ? (data.firstName + " " + data.lastName).length > 28
                ? (data.firstName + " " + data.lastName).slice(0, 28) + "..."
                : data.firstName + " " + data.lastName
              : "..."}
        </h1>
        <p className="mb-0 profile-desc">
          {type === "startup" && data?.aboutCompany
            ? data.aboutCompany.length > 85
              ? data.aboutCompany.slice(0, 85) + "..."
              : data.aboutCompany
            : data?.about
              ? data.about.length > 85
                ? data.about.slice(0, 85) + "..."
                : data.about
              : ""}
        </p>
        <div
          onClick={() =>
            type === "startup"
              ? navigate(`/startup/${data?.handle}`, {
                state: { data: data?.id },
              })
              : navigate(`/investor/${data?.handle}`, {
                state: { data: data?.id },
              })
          }
          className="w-100  view-profile-content"
        >
          <button type="button" className=" view-button mt-4">
            View Profile
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;

import { ThemeConfig } from 'antd';
import { token } from './design-tokens';
import { BUTTON } from './design-tokens/button';
import { CHECKBOX } from './design-tokens/checkbox';
import { FORM } from './design-tokens/form';
import { INPUT } from './design-tokens/input';
import { INPUT_NUMBER } from './design-tokens/inputNumber';
import { TYPOGRAPHY } from './design-tokens/typography';

export const theme: ThemeConfig = {
  components: {
    'Button': BUTTON,
    'Form': FORM,
    'Input': INPUT,
    'InputNumber': INPUT_NUMBER,
    'Typography': TYPOGRAPHY,
    'Checkbox': CHECKBOX
  },
  token,
};

import React, { useCallback, useEffect, useState } from "react";
import ImageUploader from "../components/ImageUploader";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { ObjectUpload } from "../utils/images";
import { DragDrop } from "../utils/images";
import MultiSelect from "../components/MultiSelect";
import { RxCross2 } from "react-icons/rx";
import FormLabel from "../components/FormLabel";
import InputField from "../components/InputField";
import Footer from "../components/Footer";
import Api from "../services/api";
import { toast } from "react-toastify";
import {
  handleDragEnter,
  handleDragLeave,
  handleDragOver,
  instagramUrl,
  linkedInUrl,
  validateHandle,
  websiteUrl,
} from "../helpers";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Sidebar from "../components/Sidebar";
import { countries, interestCategories } from "../constants";
import { BsCopy } from "react-icons/bs";
import ImageCropModal from "../components/imageCropModal";
import ReactDatePicker from "react-datepicker";
import { config } from "../configs";
import PageTitle from "../components/PageTitle";

const StartupEditProfile = () => {
  const token = localStorage.getItem("token");
  const roleString = JSON.parse(localStorage.getItem("userRole"));
  const [previewImage, setPreviewImage] = useState(null);
  const [modalPreviewImage, setModalPreviewImage] = useState(null);
  const [coverPhoto, setCoverPhoto] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [loader, setLoader] = useState(false);
  const [newsFeedErrors, setNewsFeedErrors] = useState(false);
  const [newsPreviewImage, setNewsPreviewImage] = useState(null);
  const [isProfilePhotoUpdated, setIsProfilePhotoUpdated] = useState(false);
  const [isCoverPhotoUpdated, setIsCoverPhotoUpdated] = useState(false);
  const [copySuccess, setCopySuccess] = useState(true);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showCoverModal, setShowCoverModal] = useState(false);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [newsData, setNewsData] = useState({
    title: "",
    description: "",
    file: null,
    role: "startup",
  });
 

  const [formValues, setFormValues] = useState({
    companyName: "",
    companyEmail: "",
    companyPhoneNumber: "",
    companyAddress: "",
    interestCategories: [],
    companyProfile: "",
    aboutCompany: "",
    city: "",
    country: "",
    dateOfEstablishment: "",
    companyWebsite: "",
    coverPhoto: "",
    founder: "",
    profilePhoto: "",
    linkedInLink: "",
    instagramLink: "",
    isPrivateProfile: false,
    handle: "",
  });
  const [errors, setErrors] = useState(false);
  const [coverImage, setCoverImage] = useState(null);
  const [profileImage, setProfileImage] = useState(null);

  const handleCheckboxChange = (e) => {
    setFormValues({
      ...formValues,
      isPrivateProfile: e?.target?.checked,
    });
  };

  const checkUniqueHandle = async (value) => {
    try {
      const response = await Api.checkHandle(token, {
        handle: value ?? formValues.handle,
        type: "startup",
      });
      if (response.success) {
        toast.success(response?.message);
      }
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };

  const handleDateChange = (date) => {
    setFormValues({
      ...formValues,
      dateOfEstablishment: date,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });

    if (name === "companyPhoneNumber") {
      const numericValue = value.replace(/\D/g, "");
      setFormValues({
        ...formValues,
        [name]: numericValue,
      });
    }
    if (name === "handle") {
      setCopySuccess(false);
      const cleanedValue = value
        .replace(/\s+/g, "-")
        .replace(/[^a-z0-9-]+/g, "")
        .replace(/-+/g, "-")
        .toLowerCase();
      checkUniqueHandle(cleanedValue);

      setFormValues({
        ...formValues,
        handle: cleanedValue,
      });
    }
  };

  const handleCopyClick = (e) => {
    e.preventDefault();
    if (copySuccess && formValues?.handle) {
      navigator.clipboard
        .writeText(`${config?.AppBaseURL}/startup/${formValues?.handle}`)
        .then(() => {
          toast.success("Profile URL copied!");
        })
        .catch((error) => {
          console.error("Error copying URL: ", error);
        });
    } else if (copySuccess && !formValues?.handle) {
      toast.error("Please enter user name");
    } else {
      toast.error("Make sure to save changes before copying.");
    }
  };

  const handleCancelImage = (e) => {
    e.stopPropagation();
    setNewsPreviewImage(null);
    document.getElementById("newsFeedImage").value = "";

    const fileInput = document.getElementById("newsFeedImage");
    fileInput.disabled = true;

    setTimeout(() => {
      fileInput.disabled = false;
      fileInput.value = "";
    }, 1000);
  };

  const handleDataChange = (e) => {
    const { name, value } = e.target;
    setNewsData({
      ...newsData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (
        !formValues.companyName ||
        !formValues.handle ||
        !formValues.country ||
        !formValues.dateOfEstablishment ||
        (formValues.companyPhoneNumber &&
          !isValidPhoneNumber(formValues.companyPhoneNumber)) ||
        (formValues.companyWebsite && !websiteUrl(formValues.companyWebsite)) ||
        (formValues.linkedInLink && !linkedInUrl(formValues.linkedInLink)) ||
        (formValues.instagramLink && !instagramUrl(formValues.instagramLink)) ||
        (formValues.handle && !validateHandle(formValues?.handle))
      ) {
        setErrors(true);
        return false;
      }
      setLoading(true);

      const formData = new FormData();
      for (const key in formValues) {
        if (
          key !== "interestCategories" &&
          key !== "coverPhoto" &&
          key !== "profilePhoto"
        ) {
          formData.append(key, formValues[key]);
        }
      }
      if (isCoverPhotoUpdated) {
        formData.append("coverPhoto", formValues?.coverPhoto);
        if (!coverPhoto) {
          formData.append("coverRemoved", true);
        }
      } else {
        formData.append("coverPhoto", null);
      }
      if (isProfilePhotoUpdated) {
        formData.append("profilePhoto", formValues?.profilePhoto);
      } else {
        formData.append("profilePhoto", null);
      }
      formData.append(
        "interestCategories",
        JSON.stringify(formValues?.interestCategories)
      );

      const response = await Api.updateStartupProfile(formData, token);
      if (response?.success) {
        if (roleString?.length < 2 && roleString?.includes("investor")) {
          localStorage.setItem("profileFlag", 1);
          localStorage.setItem(
            "userRole",
            JSON.stringify(["investor", "startup"])
          );
        }
        localStorage.setItem("companyName", formValues?.companyName);
        localStorage.setItem("companyProfilePhoto", previewImage);
        setCopySuccess(true);
        toast.success("Startup Profile Updated Succcessfully");
      } else {
        toast.error(response?.data?.message);
      }
    } catch (e) {
      toast.error(e?.data?.message ?? e?.data);
    } finally {
      setLoading(false);
    }
  };
  

  const handleImageChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];
    const maxSizeInBytes = 2 * 1024 * 1024;

    if (file) {
      const coverImageSize = 5 * 1024 * 1024;
      if (name === "coverImage" && file?.size > coverImageSize) {
        toast.error(
          "Image size is larger than 5MB. Please upload a smaller file."
        );
        e.target.value = null;
        return;
      } else if (name !== "coverImage" && file.size > maxSizeInBytes) {
        toast.error(
          "Image size is larger than 2MB. Please upload a smaller file."
        );
        e.target.value = null;
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        if (name === "coverImage") {
          setModalPreviewImage(reader.result);
          setShowCoverModal(true);
        } else if (name === "file") {
          setNewsPreviewImage(reader.result);
          setNewsData((newsFeed) => ({
            ...newsFeed,
            file: file,
          }));
        } else {
          setModalPreviewImage(reader.result);
          setShowProfileModal(true);
        }
      };
      reader.readAsDataURL(file);

      setTimeout(() => {
        e.target.value = null;
      }, 0);
    }
  };

  const handleDrop = (e, name) => {
    const maxSizeInBytes = 2 * 1024 * 1024;

    e.preventDefault();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    if (file) {
      const coverImageSize = 5 * 1024 * 1024;
      if (name === "coverImage" && file?.size > coverImageSize) {
        toast.error(
          "Image size is larger than 5MB. Please upload a smaller file."
        );
        e.target.value = null;
        return;
      } else if (name !== "coverImage" && file.size > maxSizeInBytes) {
        toast.error(
          "Image size is larger than 2MB. Please upload a smaller file."
        );
        e.target.value = null;
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        if (name === "coverImage") {
          setModalPreviewImage(reader.result);
          setShowCoverModal(true);
        } else if (name === "file") {
          setNewsPreviewImage(reader.result);
          setNewsData((newsFeed) => ({
            ...newsFeed,
            file: file,
          }));
        } else {
          setModalPreviewImage(reader.result);
          setShowProfileModal(true);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemove = () => {
    setFormValues((formValues) => ({
      ...formValues,
      coverPhoto: null,
    }));
    setCoverPhoto(null);
    setIsCoverPhotoUpdated(true);
  };

  const handlePhoneChange = (value) => {
    if (value) {
      const validation = isValidPhoneNumber(value);
      setIsPhoneNumberValid(validation);
    }
    setFormValues((formValues) => ({
      ...formValues,
      companyPhoneNumber: value,
    }));
  };

  const getStartupProfile = useCallback(async () => {
    try {
      const response = await Api.getStartupProfile(token);
      if (response.success) {
        setSelectedOptions(response?.data?.interestCategories ?? []);
        setPreviewImage(response?.data?.profilePhoto);
        setCoverPhoto(response?.data?.coverPhoto);

        setProfileImage(response?.data?.profilePhoto || null);
        setCoverImage(response?.data?.coverPhoto || null);

        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          coverPhoto: response?.data?.coverPhoto || null,
          profilePhoto: response?.data?.profilePhoto || null,
          companyEmail: response?.data?.companyEmail || "",
          companyPhoneNumber: response?.data?.companyPhoneNumber || "",
          country: response?.data?.country || "",
          city: response?.data?.city || "",
          companyAddress: response?.data?.companyAddress || "",
          interestCategories: response?.data?.interestCategories || [],
          aboutCompany: response?.data?.aboutCompany || "",
          companyName: response?.data?.companyName || "",
          companyProfile: response?.data?.companyProfile || "",
          dateOfEstablishment: response?.data?.dateOfEstablishment || "",
          companyWebsite: response?.data?.companyWebsite || "",
          linkedInLink: response?.data?.socialLinks?.linkedInLink || "",
          instagramLink: response?.data?.socialLinks?.instagramLink || "",
          founder: response?.data?.founder || "",
          handle: response?.data?.handle || "",
          isPrivateProfile: response?.data?.isPrivateProfile,
        }));

        // Load phone validation
        const phone = response?.data?.companyPhoneNumber;
        setIsPhoneNumberValid(isValidPhoneNumber(phone));
      }
    } catch (error) {
      toast.error(error.message);
    }
  }, [token]);
  
  const handleNewsFeedSubmit = async () => {
    if (!newsData?.title || !newsData?.description || !newsData?.file) {
      setNewsFeedErrors(true);
      return;
    }
    try {
      setLoader(true);
      const formDataa = new FormData();
      for (const key in newsData) {
        formDataa.append(key, newsData[key]);
      }
      const response = await Api.addNewsFeed(formDataa, token);
      if (response?.success) {
        setNewsData((newsFeed) => ({
          ...newsFeed,
          title: "",
          description: "",
          file: null,
          role: "startup",
        }));
        setNewsPreviewImage(null);
        setNewsFeedErrors(false);
        toast.success("News feed added successfully");
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error(error?.data?.message);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getStartupProfile();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [getStartupProfile]);

  return (
    <Sidebar>
      <PageTitle title="Edit Profile" />
      <div className="main-content dashboard-main">
        <div className="dash-content">
          <ImageCropModal
            showModal={showCoverModal}
            imgURL={modalPreviewImage}
            onSaveHandler={setCoverPhoto}
            currentPhoto={coverImage}
            setCurrentPhoto={setCoverImage}
            name="coverPhoto"
            setFormValues={setFormValues}
            isImageUpdated={setIsCoverPhotoUpdated}
            onModalClose={() => {
              setShowCoverModal(false);
            }}
          />
          <ImageUploader
            name="coverImage"
            handleImageChange={handleImageChange}
            previewImage={coverPhoto}
            setPreviewImage={setCoverPhoto}
            handleDragOver={handleDragOver(setIsDragging)}
            handleDragEnter={handleDragEnter(setIsDragging)}
            handleDragLeave={handleDragLeave(setIsDragging)}
            handleDrop={(e) => handleDrop(e, "coverImage")}
            handleRemove={handleRemove}
          />
          <Container fluid className="public-profile">
            <div className="status-field d-flex gap-5">
              <div className="status-craft d-flex flex-column">
                <div className="profile-image-edit">
                  <ImageCropModal
                    showModal={showProfileModal}
                    imgURL={modalPreviewImage}
                    onSaveHandler={setPreviewImage}
                    currentPhoto={profileImage}
                    setCurrentPhoto={setProfileImage}
                    name="profilePhoto"
                    setFormValues={setFormValues}
                    isImageUpdated={setIsProfilePhotoUpdated}
                    onModalClose={() => {
                      setShowProfileModal(false);
                    }}
                  />
                  <div
                    className={`drag-drop-container ${isDragging ? "dragging" : ""
                      }`}
                    onDragOver={handleDragOver(setIsDragging)}
                    onDragEnter={handleDragEnter(setIsDragging)}
                    onDragLeave={handleDragLeave(setIsDragging)}
                    onDrop={(e) => handleDrop(e, "profilePhoto")}
                  >
                    <label
                      htmlFor="file-upload"
                      className="object-upload text-center pt-5 d-flex flex-column justify-content-center align-items-center"
                    >
                      <input
                        type="file"
                        id="file-upload"
                        style={{ display: "none" }}
                        name="profilePhoto"
                        onChange={(e) => handleImageChange(e)}
                        accept="image/*"
                      />
                      <img
                        src={previewImage ? previewImage : ObjectUpload}
                        alt=""
                        className={previewImage ? "preview-image" : ""}
                      />
                      {!previewImage ? (
                        <p className="drop-desc mb-0">
                          Drop your image <br />
                          here, or{" "}
                          <span className="secondary-text secondary-desc browse-text">
                            browse
                          </span>
                        </p>
                      ) : (
                        <></>
                      )}
                    </label>
                  </div>
                </div>
                <label
                  htmlFor="public-profile-checkbox"
                  className="public-private d-flex align-items-center gap-2"
                >
                  <input
                    type="checkbox"
                    id="public-profile-checkbox"
                    className=" checkbox-box private-checkbox"
                    checked={formValues?.isPrivateProfile}
                    onChange={(e) => handleCheckboxChange(e)}
                  />
                  <FormLabel
                    className="set-status mb-0"
                    labelText="Make profile private"
                  />
                </label>
              </div>
            </div>
          </Container>
          <Container fluid className="public-profile">
            <Row className="startup-points pb-5 justify-content-between">
              <Col lg={6} md={12} xs={12}>
                <div className="form-group d-flex flex-column gap-2">
                  <FormLabel
                    className="form-label"
                    labelText="Company Name"
                    required={true}
                  />
                  <InputField
                    className="form-input"
                    placeholder="Enter Company Name"
                    name="companyName"
                    type="text"
                    value={formValues.companyName}
                    onChange={handleChange}
                  />
                  {errors && !formValues.companyName && (
                    <p className="error-message secondary-text mb-0">
                      Please enter your Company Name
                    </p>
                  )}
                </div>
              </Col>

              <Col lg={6} md={12} xs={12}>
                <div className="form-group d-flex flex-column gap-2">
                  <FormLabel
                    className="form-label align-items-center"
                    labelText="Profile URL"
                    helperText="No special characters allowed, spaces in names will convert to dashes."
                  />
                  <div className="form-input  profile-input align-items-center d-flex gap-2">
                    <p className="wrapper-text">{config?.AppBaseURL}/startup</p>
                    <InputField
                      className="form-input profile-input select-form"
                      placeholder="Enter User Name"
                      type="text"
                      name="handle"
                      value={formValues.handle}
                      onChange={handleChange}
                    />

                    <div className="tooltip-container">
                      <button
                        className="secondary-text mb-0 profile-copy-icon"
                        disabled={!formValues?.handle}
                        onClick={(e) => handleCopyClick(e)}
                      >
                        <BsCopy
                          className={
                            !formValues?.handle
                              ? "handbook-select disabled-icon"
                              : "handbook-select"
                          }
                        />
                      </button>
                    </div>
                  </div>
                  {errors && !formValues.handle ? (
                    <p className="error-message secondary-text mb-0">
                      Please enter user name
                    </p>
                  ) : errors && !validateHandle(formValues?.handle) ? (
                    <p className="error-message secondary-text mb-0">
                      User name contain only alphabets and numbers
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
              <Col lg={6} md={12} xs={12}>
                <div className="form-group d-flex flex-column gap-2">
                  <FormLabel className="form-label" labelText="Email Address" />
                  <InputField
                    className="form-input"
                    placeholder="Enter Email Address"
                    type="email"
                    name="companyEmail"
                    value={formValues.companyEmail}
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col lg={6} md={12} xs={12}>
                <div className="form-group d-flex flex-column gap-2">
                  <FormLabel
                    className="form-label"
                    labelText="Company Phone Number "
                  />
                  <div className="input-field form-input d-flex gap-2">
                    <PhoneInput
                      defaultCountry="HK"
                      placeholder="Enter Company Phone Number"
                      name="companyPhoneNumber"
                      addInternationalOption={false}
                      value={formValues?.companyPhoneNumber}
                      onChange={handlePhoneChange}
                    />
                  </div>
                  {errors &&
                    formValues.companyPhoneNumber &&
                    !isPhoneNumberValid ? (
                    <p className="error-message secondary-text mb-0">
                      Please enter valid phone number
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
              <Col lg={6} md={12} xs={12}>
                <div className="form-group d-flex flex-column gap-2">
                  <FormLabel
                    className="form-label"
                    labelText="Company Elevator Pitch"
                  />
                  <InputField
                    className="form-input"
                    placeholder="Company Elevator Pitch"
                    type="text"
                    name="companyProfile"
                    value={formValues.companyProfile}
                    onChange={handleChange}
                    maxLength="100"
                  />
                </div>
              </Col>
              <Col lg={12} md={12} xs={12}>
                <div className="form-group d-flex flex-column gap-2">
                  <FormLabel
                    className="form-label"
                    labelText="About Company"
                  />
                  <textarea
                    id=""
                    cols="30"
                    rows="10"
                    className="form-input about-company"
                    name="aboutCompany"
                    value={formValues.aboutCompany}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </Col>

              <Col lg={6} md={12} xs={12}>
                <div className="form-group d-flex flex-column gap-2">
                  <FormLabel
                    className="form-label"
                    labelText="Company Address "
                  />
                  <InputField
                    className="form-input "
                    placeholder="Enter Company Address"
                    name="companyAddress"
                    value={formValues.companyAddress}
                    onChange={handleChange}
                  />
                </div>
              </Col>

              <Col lg={6} md={12} xs={12}>
                <div className="form-group d-flex flex-column gap-2">
                  <FormLabel
                    className="form-label"
                    labelText="Company website (if any)"
                  />
                  <InputField
                    className="form-input"
                    placeholder="website Link"
                    type="text"
                    name="companyWebsite"
                    value={formValues.companyWebsite}
                    onChange={handleChange}
                  />
                  {errors &&
                    formValues.companyWebsite &&
                    !websiteUrl(formValues.companyWebsite) && (
                      <p className="error-message secondary-text mb-0">
                        Please enter valid website URL
                      </p>
                    )}
                </div>
              </Col>
              <Col lg={6} md={12} xs={12}>
                <div className="form-group d-flex flex-column gap-2">
                  <FormLabel
                    className="form-label label-phone"
                    labelText="Country "
                    required={true}
                  />
                  <select
                    id="country"
                    name="country"
                    value={formValues.country}
                    onChange={handleChange}
                    className="form-input form-select-select"
                  >
                    <option value="" disabled selected>
                      Select a country
                    </option>
                    {countries.map((country, index) => (
                      <option key={index} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                  {errors && !formValues.country && (
                    <p className="error-message secondary-text mb-0">
                      Please enter your country
                    </p>
                  )}
                </div>
              </Col>
              <Col lg={6} md={12} xs={12}>
                <div className="form-group d-flex flex-column gap-2">
                  <FormLabel className="form-label" labelText="City" />
                  <InputField
                    className="form-input"
                    placeholder="Enter City"
                    type="text"
                    name="city"
                    value={formValues.city}
                    onChange={handleChange}
                  />
                </div>
              </Col>

            </Row>
          </Container>
          <Container fluid className="public-profile mt-4 startup-points pb-5">
            <Row className="profile-row justify-content-between mt-4">
              <Col lg={6} md={12} xs={12}>
                <div className="form-group d-flex flex-column gap-2">
                  <FormLabel className="form-label" labelText="Founder(s) " />
                  <InputField
                    className="form-input"
                    placeholder="Enter Company Founder(s) "
                    type="text"
                    name="founder"
                    value={formValues.founder}
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col lg={6} md={12} xs={12}>
                <div className="form-group d-flex flex-column gap-2">
                  <FormLabel
                    className="form-label"
                    labelText="Date Of Establishment"
                    required={true}
                  />
                  <ReactDatePicker
                    className="form-input date-input"
                    selected={
                      formValues.dateOfEstablishment
                        ? new Date(formValues.dateOfEstablishment)
                        : null
                    }
                    onChange={handleDateChange}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd | mm | yyyy"
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={15}
                  />
                  {errors && !formValues.dateOfEstablishment && (
                    <p className="error-message secondary-text mb-0">
                      Please enter date of establishment
                    </p>
                  )}
                </div>
              </Col>

              <Col lg={6} md={12} xs={12}>
                <div className="form-group d-flex flex-column gap-2">
                  <FormLabel className="form-label" labelText="Linkedin" />
                  <InputField
                    className="form-input"
                    placeholder="Linkedin URL"
                    type="text"
                    name="linkedInLink"
                    value={formValues?.linkedInLink}
                    onChange={handleChange}
                  />
                  {errors &&
                    formValues.linkedInLink &&
                    !linkedInUrl(formValues.linkedInLink) && (
                      <p className="error-message secondary-text mb-0">
                        Please enter valid linkedIn URL
                      </p>
                    )}
                </div>
              </Col>
              <Col lg={6} md={12} xs={12}>
                <div className="form-group d-flex flex-column gap-2">
                  <FormLabel className="form-label" labelText="Instagram " />
                  <InputField
                    className="form-input"
                    placeholder="Instagram URL"
                    type="text"
                    name="instagramLink"
                    value={formValues?.instagramLink}
                    onChange={handleChange}
                  />
                  {errors &&
                    formValues.instagramLink &&
                    !instagramUrl(formValues.instagramLink) && (
                      <p className="error-message secondary-text mb-0">
                        Please enter valid instagram URL
                      </p>
                    )}
                </div>
              </Col>
              <Col lg={6} md={12} xs={12}>
                <div className="form-group d-flex flex-column gap-2">
                  <FormLabel
                    className="form-label"
                    labelText="Startup category (you may select more than one)"
                  />
                  <MultiSelect
                    value={formValues?.interestCategories}
                    onChange={handleChange}
                    name="interestCategories"
                    setFormInfo={setFormValues}
                    formInfo={formValues}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                    formName="interestCategories"
                    type="multiple"
                    options={interestCategories}
                  />
                </div>
              </Col>
              <Col lg={6} md={12} xs={12}>
                <div className="updates-buttons startup-updates  d-flex justify-content-end align-items-center gap-3 ">
                  <button className="view-button update-btn mt-0">
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="primary-btn update-btn d-flex align-items-center justify-content-center gap-2"
                    onClick={(e) => handleSubmit(e)}
                  >
                    Update
                    {loading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      <></>
                    )}
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
          <Container fluid className="public-profile mt-4">
            <div className="feed-footer">
              <Row className="post-feeds">
                <Col lg={12} md={12} xs={12}>
                  <div className="profile-category d-flex justify-content-between align-items-center">
                    <h1 className="profile-heading mb-0">
                      Startup News/Updates
                      <span className="secondary-text slash-trending">_</span>
                    </h1>
                  </div>
                </Col>
                <Col lg={6} md={12} xs={12}>
                  <div
                    name="file"
                    className={`drag-drop-container ${isDragging ? "profile-drag dragging" : "profile-drag"
                      }`}
                    onDragOver={handleDragOver(setIsDragging)}
                    onDragEnter={handleDragEnter(setIsDragging)}
                    onDragLeave={handleDragLeave(setIsDragging)}
                    onDrop={(e) => handleDrop(e, "file")}
                  >
                    <input
                      type="file"
                      id="newsFeedImage"
                      accept="image/*"
                      name="file"
                      onChange={(e) => handleImageChange(e)}
                      style={{ display: "none" }}
                    />
                    <label
                      htmlFor="newsFeedImage"
                      className="text-center position-relative"
                    >
                      {newsPreviewImage ? (
                        <>
                          <img
                            src={newsPreviewImage ? newsPreviewImage : DragDrop}
                            alt="Drag and drop icon"
                            className=" feed-img mt-0 mb-0 "
                          />
                          <div className="cancel-img d-flex align-items-center justify-content-center">
                            <RxCross2
                              className="cancel-img-icon"
                              onClick={handleCancelImage}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <img
                            src={DragDrop}
                            alt="Drag and drop icon"
                            className="feed-img mt-0 mb-0"
                          />
                          <p className="desc-cover">
                            Drop your image here, or&nbsp;
                            <span className="secondary-text browse-text">
                              browse
                            </span>
                          </p>
                        </>
                      )}
                    </label>
                  </div>
                  {newsFeedErrors && !newsData.file && (
                    <p className="error-message secondary-text mb-0 mt-2">
                      Please select image
                    </p>
                  )}
                </Col>
                <Col
                  lg={6}
                  md={12}
                  xs={12}
                  className="profile-fields profile-fields-feed d-flex flex-column gap-4 mt-4"
                >
                  <div className=" form-group d-flex flex-column gap-2 mt-2">
                    <FormLabel
                      className="form-label"
                      labelText="Title"
                      required={true}
                    />
                    <InputField
                      className="form-input"
                      placeholder="Enter Name"
                      type="text"
                      name="title"
                      value={newsData?.title}
                      onChange={(e) => handleDataChange(e)}
                    />
                    {newsFeedErrors && !newsData.title && (
                      <p className="error-message secondary-text mb-0">
                        Please enter title
                      </p>
                    )}
                  </div>
                  <div className="form-group d-flex flex-column gap-2 mt-1">
                    <FormLabel
                      className="form-label"
                      labelText="Description"
                      required={true}
                    />
                    <textarea
                      id=""
                      cols="30"
                      rows="10"
                      className="form-input about-text"
                      name="description"
                      value={newsData?.description}
                      placeholder="Enter Description"
                      onChange={(e) => handleDataChange(e)}
                    ></textarea>
                    {newsFeedErrors && !newsData.description && (
                      <p className="error-message secondary-text mb-0">
                        Please enter description
                      </p>
                    )}
                  </div>
                </Col>
                <Col lg={12} md={12} xs={12}>
                  <div className="d-flex justify-content-end align-items-center gap-3 mt-4 mb-2">
                    <button
                      className="primary-btn update-button"
                      onClick={(e) => handleNewsFeedSubmit(e)}
                    >
                      Post{" "}
                      {loader ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <></>
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <div className="mt-5">
          <Footer className="simple-container" />
        </div>
      </div>
    </Sidebar>
  );
};

export default StartupEditProfile;

import React from "react";
import { VscCalendar } from "react-icons/vsc";
import { dummyProfile, feedsImage } from "../utils/images";
import { useNavigate } from 'react-router-dom';
import { getTimeAgo } from "../helpers";

const FeedsCard = ({ newsfeed, text }) => {
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "short", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const handleReadMore = (id) => {
    localStorage.setItem("news-feed", JSON.stringify(newsfeed))
    navigate(`/post/${id}`);
    window.scroll(0, 0)
  };

  return (
    <div
      className={text ? text : "feeds-post d-flex justify-content-start gap-3"}
    >
      <div className="post-image">
        <img src={newsfeed?.image ?? feedsImage} alt="feeds" />
      </div>
      <div className="post-content mt-2">
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="feeds-heading">{newsfeed?.title}</h1>
          <p className="feeds-ago mb-0">{getTimeAgo(newsfeed?.createdAt)}</p>
        </div>
        <span className="feed-time mt-0 mb-1">
          <VscCalendar />
          <p className="mb-0">{formatDate(newsfeed?.createdAt)}</p>
        </span>
        <p className="feeds-desc news-feed-desc">
          {newsfeed?.description?.length > 250
            ? newsfeed?.description?.slice(0, 250) + " ..."
            : newsfeed?.description}
        </p>
        <div className="feeds-content-button d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center gap-2">
            <div className="profilep-img  feed-profile-img">
              <img
                src={
                  newsfeed?.userImage === "" || !newsfeed?.userImage
                    ? dummyProfile
                    : newsfeed?.userImage
                      ? newsfeed?.userImage
                      : dummyProfile
                }
                alt="Profile"
              />
            </div>
            <p className="feed-user mb-0">{newsfeed?.name && newsfeed.name.length > 20 ? newsfeed.name.slice(0, 20) + "..." : newsfeed.name}</p>
          </div>
          <button className="view-button read-btn mt-0" onClick={() => handleReadMore(newsfeed.id)}>Read More</button>
        </div>
      </div>
    </div>
  );
};

export default FeedsCard;

import React from "react";
import { BiSolidRightArrow } from "react-icons/bi";

const Digitalbox = () => {
  return (
    <div className="digital-platform d-flex justify-content-center flex-column">
      <div className="digital-content d-flex gap-2 flex-column">
        <div className="digital-left-arrow d-flex align-items-center gap-2">
          <span>
            <BiSolidRightArrow className="digital-arrow text-white" />
          </span>
          <span className="digital-text">Where Smart</span>
        </div>
        <h1 className="digital-heading d-flex gap-3 flex-column">
          <span className="digital-investment">
            Investments
          </span>
          <span>Flow.</span>
        </h1>
      </div>
      <p className="digital-desc">
        Unleash the power of angel investing.
      </p>
    </div>
  )
}

export default Digitalbox
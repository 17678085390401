import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import { HiOutlineArrowRight } from "react-icons/hi2";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { User, DummyImage } from "../utils/images/index";
import Digitalbox from "../components/Digitalbox";
import FormLabel from "../components/FormLabel";
import AngelLogo from "../components/AngelLogo";
import InputField from "../components/InputField";
import Api from "../services/api";
import { toast } from "react-toastify";
import MultiSelect from "../components/MultiSelect";
import {
  handleDragEnter,
  handleDragLeave,
  handleDragOver,
  instagramUrl,
  linkedInUrl,
} from "../helpers";
import { RoleContext } from "../context/currentRole";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { countries, interestCategories } from "../constants";
import ImageCropModal from "../components/imageCropModal";
import ReactDatePicker from "react-datepicker";
import PageTitle from "../components/PageTitle";

const ProfileSetup = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  let user = JSON.parse(localStorage.getItem("user"));
  const [userImage, setUserImage] = useState(user?.profilePhoto ?? null);
  const [isDragging, setIsDragging] = useState(false);
  const [previewImage, setPreviewImage] = useState(user?.profilePhoto ?? null);
  const [errors, setErrors] = useState(false);
  const [stepper, setStepper] = useState(0);
  const [error, setError] = useState("");
  const [selectedRoles, setSelectedRoles] = useState(user?.role ?? []);
  const [loading, setLoading] = useState(false);
  const { setCurrentRole } = useContext(RoleContext);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [modalPreviewImage, setModalPreviewImage] = useState(null);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [isPhotoUpdated, setIsPhotoUpdated] = useState(false);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);

  // const [value, setValue] = useState("+9243434434");
  const [formValues, setFormValues] = useState({
    firstName: user?.firstName ?? "",
    lastName: user?.lastName ?? "",
    phoneNumber: user?.phoneNumber ?? "",
    country: user?.country ?? "",
    file: null,
    role: user?.role ?? [],
  });
  const [formInfo, setFormInfo] = useState({
    interestCategories: [],
    companyName: "",
    founder: "",
    dateOfEstablishment: "",
    companyProfile: "",
    companyWebsite: "",
    linkedinLink: "",
    instagramLink: "",
  });

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handlePhoneChange = (value) => {
    if (value) {
      const validation = isValidPhoneNumber(value);
      setIsPhoneNumberValid(validation);
    }
    setFormValues((formValues) => ({
      ...formValues,
      phoneNumber: value,
    }));
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const maxSizeInBytes = 2 * 1024 * 1024;
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      if (file.size > maxSizeInBytes) {
        toast.error(
          "Image size is larger than 2MB. Please upload a smaller file."
        );
        e.target.value = null;
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        setModalPreviewImage(reader.result);
        setShowProfileModal(true);
        // setPreviewImage(reader.result);
        // setFormValues((formValues) => ({
        //   ...formValues,
        //   file: file,
        // }));
      };
      reader.readAsDataURL(file);
    }
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const maxSizeInBytes = 2 * 1024 * 1024;

    if (file) {
      if (file.size > maxSizeInBytes) {
        toast.error(
          "Image size is larger than 2MB. Please upload a smaller file."
        );
        e.target.value = null;
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        // setPreviewImage(reader.result);
        setModalPreviewImage(reader.result);
        setShowProfileModal(true);
      };
      reader.readAsDataURL(file);
    }
    // setFormValues((formValues) => ({
    //   ...formValues,
    //   file: file,
    // }));
  };
  const handleInfoChange = (e) => {
    const { name, value } = e.target;
    setFormInfo({
      ...formInfo,
      [name]: value,
    });
  };

  const handleDateChange = (date) => {
    setFormInfo({
      ...formInfo,
      dateOfEstablishment: date,
    });
  };

  const handleCountryChange = (e) => {
    const { value } = e.target;
    setSelectedCountry(value);
    setFormValues({
      ...formValues,
      country: value,
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });

    if (name === "phoneNumber") {
      const numericValue = value.replace(/\D/g, "");
      setFormValues({
        ...formValues,
        [name]: numericValue,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (stepper === 0) {
      if (
        !formValues.firstName ||
        !formValues.lastName ||
        !formValues.phoneNumber ||
        !formValues.country ||
        !isPhoneNumberValid
      ) {
        setErrors(true);
        return false;
      }

      setStepper(1);
      setErrors(false);
    } else {
      if (!formInfo.companyName) {
        setErrors(true);
        return false;
      }
      setErrors(false);
      setStepper(3);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const handleRoleSelection = (role) => {
    setError("");
    let updatedRoles;
    if (selectedRoles.includes(role)) {
      updatedRoles = selectedRoles.filter((r) => r !== role);
    } else {
      updatedRoles = [...selectedRoles, role];
    }

    setSelectedRoles(updatedRoles);

    setFormValues((prev) => ({
      ...prev,
      role: updatedRoles,
    }));
  };

  const handleNextButtonClick = async (e) => {
    e.preventDefault();
    if (selectedRoles.length === 0) {
      setError("Please select at least one role.");
      return;
    }
    try {
      setLoading(true);

      const formData = new FormData();
      for (const key in formValues) {
        if (key !== "role" && key !== "phoneNumber" && key !== "file") {
          formData.append(key, formValues[key]);
        }
      }
      formData.append("phoneNumber", formValues.phoneNumber);
      if (isPhotoUpdated) {
        formData.append("file", formValues?.file);
      }
      formData.append("role", JSON.stringify(formValues?.role));

      if (formValues?.role?.length === 2) {
        formData.append("profileFlag", 1);
      } else if (
        formValues?.role?.length === 1 &&
        formValues?.role?.includes("investor")
      ) {
        formData.append("profileFlag", 2);
      } else {
        formData.append("profileFlag", 3);
      }
      const response = await Api.basicProfile(formData, token);
      if (response.success) {
        localStorage.setItem("userRole", JSON.stringify(selectedRoles));

        localStorage.setItem(
          "userName",
          formValues?.firstName + " " + formValues?.lastName
        );
        localStorage.setItem("profilePhoto", previewImage);

        localStorage.setItem("companyProfilePhoto", previewImage);
        localStorage.setItem("profileFlag", response?.data?.profileFlag);

        localStorage.setItem("userId", response.data?.userId);
        localStorage.setItem("startupId", response.data?.startupId);
        localStorage.setItem(
          "currentRole",
          selectedRoles?.includes("investor") ? "Investor" : "Startup"
        );
        setCurrentRole(
          selectedRoles?.includes("investor") ? "Investor" : "Startup"
        );
        setErrors(false);
        if (selectedRoles.includes("startup")) setStepper(2);
        else {
          navigate("/home");
        }
      }
    } catch (error) {
      toast.error(error?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSkip = async (e) => {
    e.preventDefault();
    try {
      const response = await Api.isSkipped(token);
      if (response.success) {
        navigate("/home");
      }
    } catch (error) {
      console.error(error?.data?.message);
    }
  };

  const handleDetailSubmit = async (e) => {
    e.preventDefault();
    if (
      !formInfo.dateOfEstablishment ||
      (formInfo.linkedinLink && !linkedInUrl(formInfo.linkedinLink)) ||
      (formInfo.instagramLink && !instagramUrl(formInfo.instagramLink))
    ) {
      setErrors(true);
      return false;
    }
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const response = await Api.updateStartup(formInfo, token);
      if (response.success) {
        localStorage.setItem("companyName", formInfo?.companyName);
        navigate("/home");
      }
    } catch (error) {
      toast.error(error?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location?.state?.stepper) {
      setStepper(location?.state?.stepper);
    }
  }, [location?.state]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [stepper]);

  return (
    <>
    <PageTitle title="Profile Setup" />
    <Container fluid className="main-wrapper">
      <Row>
        <Col
          lg={6}
          md={12}
          sm={12}
          className="digital-banner d-flex align-items-center justify-content-center"
        >
          <Digitalbox />
        </Col>
        {stepper === 0 ? (
          <Col lg={6} md={12} sm={12} className="user-transformation">
            <AngelLogo />
            <div className="create-account">
              <div className=" d-flex gap-3 align-items-center">
                <h1 className="main-heading mb-0 mt-1">User Setup</h1>
                <img src={User} alt="Logo" className="user-img" />
              </div>
              <p className="main-desc mt-3 mb-0 ">
                Tell us a little bit about yourself. Don't worry, you can always
                edit this information again later.
              </p>

              <Form className="mt-4 login-form" onSubmit={handleSubmit}>
                <ImageCropModal
                  showModal={showProfileModal}
                  imgURL={modalPreviewImage}
                  onSaveHandler={setPreviewImage}
                  currentPhoto={userImage}
                  setCurrentPhoto={setUserImage}
                  name="file"
                  isImageUpdated={setIsPhotoUpdated}
                  setFormValues={setFormValues}
                  onModalClose={() => {
                    setShowProfileModal(false);
                  }}
                />
                <div
                  className={`image-uploader mb-5 ${
                    isDragging ? "dragging" : ""
                  }`}
                  onDragOver={handleDragOver(setIsDragging)}
                  onDragEnter={handleDragEnter(setIsDragging)}
                  onDragLeave={handleDragLeave(setIsDragging)}
                  onDrop={(e) => handleDrop(e)}
                >
                  <input
                    type="file"
                    id="fileInput"
                    onChange={handleImageChange}
                    accept="image/*"
                  />
                  <label htmlFor="fileInput" className="upload-label">
                    <img
                      src={previewImage ? previewImage : DummyImage}
                      alt=""
                      className="preview-image"
                    />
                  </label>
                </div>
                <Row className="profile-setup">
                  <Col lg={6} md={12} xs={12}>
                    <div className="form-group form-input-label d-flex flex-column gap-2 mb-4">
                      <FormLabel
                        className="form-label"
                        labelText="First Name"
                        required={true}
                      />
                      <InputField
                        className="form-input"
                        placeholder="Enter First Name"
                        type="text"
                        name="firstName"
                        value={formValues.firstName}
                        onChange={handleChange}
                      />
                      {errors && !formValues.firstName && (
                        <p className="error-message secondary-text mb-0 mt-1">
                          Please Enter First Name
                        </p>
                      )}
                    </div>
                  </Col>
                  <Col lg={6} md={12} xs={12}>
                    <div className="form-group form-input-label d-flex flex-column gap-2 mb-4">
                      <FormLabel
                        className="form-label"
                        labelText="Last Name"
                        required={true}
                      />
                      <InputField
                        className="form-input"
                        placeholder="Enter Last Name"
                        type="text"
                        name="lastName"
                        value={formValues.lastName}
                        onChange={handleChange}
                        required
                      />
                      {errors && !formValues.lastName && (
                        <p className="error-message secondary-text mb-0 mt-1">
                          Please Enter Last Name
                        </p>
                      )}
                    </div>
                  </Col>
                  <Col lg={6} md={12} xs={12}>
                    <div className="form-group form-input-label d-flex flex-column gap-2 mb-4">
                      <FormLabel
                        className="form-label"
                        labelText="Phone Number"
                        required={true}
                      />

                      <div className="input-field form-input d-flex gap-2">
                        <PhoneInput
                          defaultCountry="HK"
                          placeholder="Enter phone number"
                          addInternationalOption={false}
                          name="phoneNumber"
                          value={formValues?.phoneNumber}
                          onChange={handlePhoneChange}
                        />
                      </div>
                      {errors && !formValues.phoneNumber ? (
                        <p className="error-message secondary-text mb-0 mt-1">
                          Please Enter Phone Number
                        </p>
                      ) : errors && !isPhoneNumberValid ? (
                        <p className="error-message secondary-text mb-0">
                          Please enter valid phone number
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>

                  <Col lg={6} md={12} xs={12}>
                    <div className="form-group form-input-label d-flex flex-column gap-2 mb-4">
                      <FormLabel
                        className="form-label"
                        labelText="Country"
                        required={true}
                      />
                     
                      <select
                        id="country"
                        value={selectedCountry}
                        onChange={handleCountryChange}
                        className="form-input form-select-select"
                      >
                        <option value="" disabled selected>
                          Select a country
                        </option>
                        {countries.map((country, index) => (
                          <option key={index} value={country}>
                            {country}
                          </option>
                        ))}
                      </select>
                      {errors && !formValues.country && (
                        <p className="error-message secondary-text mb-0 mt-1">
                          Please Enter Country
                        </p>
                      )}
                    </div>
                  </Col>
                </Row>
                <button
                  type="submit"
                  className=" primary-btn go-profile  mt-4 text-white d-flex gap-2 justify-content-center align-items-center"
                >
                  Next
                  <HiOutlineArrowRight className="right-arrow" />
                </button>
              </Form>
            </div>
          </Col>
        ) : stepper === 1 ? (
          <Col lg={6} md={12} sm={12} className="user-transformation">
            <AngelLogo />
            <Row className="skip-category align-items-center">
              <Col lg={6}>
                <Link
                  to="/profile-setup"
                  className="secondary-text"
                  onClick={() => setStepper(0)}
                >
                  Back
                </Link>
              </Col>
            </Row>
            <div className="create-account create-category">
              <div className=" d-flex gap-2 align-items-center">
                <h1 className="main-heading mb-0 mt-1">Profile Setup</h1>
                <img src={User} alt="Logo" className="user-img" />
              </div>

              <p className="main-desc mt-3 mb-4">
                Tell us a little bit about yourself. Don’t worry, you can always
                edit this information again later.
              </p>

              <Form className="mt-4 d-flex flex-column gap-3 login-form">
                <FormLabel
                  className="form-label mb-2"
                  labelText="Select Role(s)"
                  required={true}
                />

                <div className="profile-category mt-3 mb-4 d-flex flex-row flex-wrap justify-content-around">
                <div className="category-item-steps d-flex align-items-center gap-3">
                    <InputField
                      type="checkbox"
                      className="checkbox-box"
                      name="startup"
                      id="startup"
                      checked={formValues.role?.includes("startup")}
                      onChange={() => handleRoleSelection("startup")}
                    />
                    <p className="category-text">Startup</p>
                  </div>
                  <div className="category-item-steps d-flex align-items-center gap-3">
                    <InputField
                      type="checkbox"
                      className="checkbox-box"
                      name="investor"
                      id="investor"
                      checked={formValues.role?.includes("investor")}
                      onChange={() => handleRoleSelection("investor")}
                    />
                    <p className="category-text">Investor</p>
                  </div>
                </div>
                {error && (
                  <p className="error-message secondary-text mb-1 mt-1">
                    {error}
                  </p>
                )}
                <p className="note-desc mb-0">
                  <span>
                    <b>Note: &nbsp;</b>
                  </span>
                  Select Investor to create an investor profile for yourself.
                  Select Startup to create your startup’s company profile. You
                  can also select both to create two types of profiles under
                  your account.
                </p>
                <button
                  className=" primary-btn go-profile text-white d-flex gap-2 justify-content-center align-items-center"
                  onClick={(e) => handleNextButtonClick(e)}
                >
                  {"Proceed"}
                  {loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    <HiOutlineArrowRight className="right-arrow" />
                  )}
                </button>
              </Form>
            </div>
          </Col>
        ) : stepper === 2 ? (
          <Col
            lg={6}
            md={12}
            sm={12}
            className="user-transformation user-satisfaction"
          >
            <AngelLogo />
            <Row className="skip-now align-items-center">
              <div className="d-flex justify-content-between align-items-center">
                <Link className="secondary-text" onClick={() => setStepper(1)}>
                  Back
                </Link>
                <div className="secondary-text">
                  <div className="tooltip-container">
                    <p className="secondary-text mb-0">Skip</p>
                    <div className="tooltip" onClick={(e) => handleSkip(e)}>
                      Skip for now
                    </div>
                  </div>
                </div>
              </div>
            </Row>

            <div className="create-account info-account">
              <div className=" d-flex gap-3 align-items-center">
                <h1 className="main-heading mb-0 mt-1">Profile Setup</h1>
                <img src={User} alt="Logo" className="user-img" />
              </div>

              <p className="main-desc mt-3 mb-0">
                Tell us a little bit about your company. Don’t worry, you can always
                edit this information again later.
              </p>

              <Form className="mt-4 login-form" onSubmit={handleSubmit}>
                <div className="form-group form-input-label d-flex flex-column gap-2 mb-4">
                  <FormLabel
                    className="form-label"
                    labelText="Company Category/Industry"
                  />
                  <MultiSelect
                    value={formInfo.interestCategories}
                    onChange={handleInfoChange}
                    name="interestCategories"
                    setFormInfo={setFormInfo}
                    formInfo={formInfo}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                    formName="interestCategories"
                    type="multiple"
                    options={interestCategories}
                  />
                </div>
                <div className="form-group form-input-label d-flex flex-column gap-2 mb-4">
                  <FormLabel
                    className="form-label"
                    labelText="Company Name"
                    required={true}
                  />
                  <InputField
                    className="form-input"
                    placeholder="Company Name"
                    type="text"
                    name="companyName"
                    value={formInfo.companyName}
                    onChange={handleInfoChange}
                  />
                  {errors && !formInfo.companyName && (
                    <p className="error-message secondary-text mb-0 mt-1">
                      Please enter your company name
                    </p>
                  )}
                </div>
                <div className="form-group form-input-label d-flex flex-column gap-2 mb-4">
                  <FormLabel className="form-label" labelText="Founder(s)" />
                  <InputField
                    className="form-input"
                    placeholder="Founder(s)"
                    type="text"
                    name="founder"
                    value={formInfo.founder}
                    onChange={handleInfoChange}
                  />
                </div>
                <button
                  className=" primary-btn  go-profile mt-4 text-white d-flex gap-3 justify-content-center align-items-center"
                  type="submit"
                >
                  Next
                  <HiOutlineArrowRight className="right-arrow" />
                </button>
              </Form>
            </div>
          </Col>
        ) : stepper === 3 ? (
          <Col lg={6} md={12} sm={12} className="user-transformation">
            <AngelLogo />
            <Row className="skip-now align-items-center">
              <div className="d-flex justify-content-between align-items-center">
                <Link className="secondary-text" onClick={() => setStepper(2)}>
                  Back
                </Link>
                <Link to="/home" className="secondary-text">
                  <div className="tooltip-container">
                    <p className="secondary-text mb-0">Skip</p>
                    <div className="tooltip" onClick={(e) => handleSkip(e)}>
                      Skip for now
                    </div>
                  </div>
                </Link>
              </div>
            </Row>

            <div className="create-account info-account">
              <div className=" d-flex gap-2 align-items-center">
                <h1 className="main-heading mb-0 mt-1">Profile Setup</h1>
                <img src={User} alt="Logo" className="user-img" />
              </div>

              <p className="main-desc mt-3 mb-0">
                Tell us a little bit about yourself. Don’t worry, you can always
                edit this information again later.
              </p>

              <Form className="mt-4 login-form" onSubmit={handleDetailSubmit}>
                <div className="form-group form-input-label d-flex flex-column gap-2 mb-4">
                  <FormLabel
                    className="form-label"
                    labelText="Date of Establishment"
                    required={true}
                  />
                  <ReactDatePicker
                    className="form-input date-input"
                    selected={
                      formInfo.dateOfEstablishment
                        ? new Date(formInfo.dateOfEstablishment)
                        : null
                    }
                    onChange={handleDateChange}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd | mm | yyyy"
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={15}
                  />
                  {errors && !formInfo.dateOfEstablishment && (
                    <p className="error-message secondary-text mb-0 mt-1">
                      Please enter your date of establishment
                    </p>
                  )}
                </div>
                <div className="form-group form-input-label d-flex flex-column gap-2 mb-4">
                  <FormLabel
                    className="form-label"
                    labelText="Company Elevator Pitch (max 300 words)"
                  />
                  <InputField
                    className="form-input"
                    placeholder="Company Elevator Pitch"
                    type="text"
                    name="companyProfile"
                    value={formInfo.companyProfile}
                    onChange={handleInfoChange}
                    maxLength="300"
                  />

                </div>
                <div className="form-group form-input-label d-flex flex-column gap-2 mb-4">
                  <FormLabel
                    className="form-label"
                    labelText="Company website"
                  />
                  <InputField
                    className="form-input"
                    placeholder="Website link"
                    type="text"
                    name="companyWebsite"
                    value={formInfo.companyWebsite}
                    onChange={handleInfoChange}
                  />
                </div>
                <div className="form-group form-input-label d-flex flex-column gap-2 mb-4">
                  <FormLabel className="form-label" labelText="LinkedIn" />
                  <InputField
                    className="form-input"
                    placeholder="LinkedIn URL"
                    type="text"
                    name="linkedinLink"
                    value={formInfo.linkedinLink}
                    onChange={handleInfoChange}
                  />
                  {errors && (
                    <p className="error-message secondary-text mb-0 mt-1">
                      {errors &&
                      formInfo.linkedinLink &&
                      !linkedInUrl(formInfo.linkedinLink)
                        ? "Please enter a valid linkedin URL"
                        : ""}
                    </p>
                  )}
                </div>
                <div className="form-group form-input-label d-flex flex-column gap-2 mb-4">
                  <FormLabel className="form-label" labelText="Instagram" />
                  <InputField
                    className="form-input"
                    placeholder="Instagram URL"
                    type="text"
                    name="instagramLink"
                    value={formInfo.instagramLink}
                    onChange={handleInfoChange}
                  />
                  {errors && (
                    <p className="error-message secondary-text mb-0 mt-1">
                      {errors &&
                      formInfo.instagramLink &&
                      !linkedInUrl(formInfo.instagramLink)
                        ? "Please enter a valid Instagram link"
                        : ""}
                    </p>
                  )}
                </div>
                <button
                  className=" primary-btn  go-profile mt-4  text-white d-flex gap-2 justify-content-center align-items-center"
                  type="submit"
                >
                  Save
                  {loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    <HiOutlineArrowRight className="right-arrow" />
                  )}
                </button>
              </Form>
            </div>
          </Col>
        ) : (
          <></>
        )}
      </Row>
    </Container>
    </>
  );
};

export default ProfileSetup;

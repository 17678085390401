import React from "react";
import { DollarIcon } from "../utils/images/index";

const ProfileRatingsSection = ({ role, formValues }) => {
  return (
    <div className="ratings-profiles d-flex align-items-center flex-wrap ratings-profile mt-3">
      {role === "investor" && (
        <div className="d-flex flex-row gap-2 align-items-center">
          <img src={DollarIcon} alt="investment" />
          <span className="item-name">
            {formValues?.totalInvestment ? `$${formValues.totalInvestment} Investments` : "$0 Investments"}
          </span>
        </div>
      )}
    </div>
  );
};

export default ProfileRatingsSection;

import React from 'react'
import Sidebar from '../components/Sidebar'
import ChatWelcome from '../components/ChatWelcome'
import { Container } from 'react-bootstrap'
import Footer from '../components/Footer'

// TODO - Implement messaging functionality
// ChatWelcome is a coming soon placeholder for the moment
const Chat = () => {
    return (
        <Sidebar>
            {/* <ChatContent /> */}
            <Container fluid className="main-content dashboard-main">
                <h1 className="trending-heading mb-4 mt-4">
                    Inbox
                    <span className="secondary-text slash-trending">_</span>
                </h1>
                <ChatWelcome />
            </Container>
            <Footer className="simple-container" />
        </Sidebar>
    )
}

export default Chat
import { Gif } from "../../utils/images"

const Loader = () => {
  return (
    <div className="tw-relative tw-bg-[url('../../assets/images/loader-bg.png')] tw-bg-center tw-bg-cover tw-bg-no-repeat">
      <div className="tw-min-h-screen tw-backdrop-blur-[5px] tw-flex tw-flex-col tw-justify-center tw-self-center tw-p-5 tw-rounded-[10px]">
          <img className="tw-block tw-shadow-[0px_4px_58.6px_rgba(149,149,149,0.25)] tw-mx-auto tw-my-0 tw-rounded-[50%]" src={Gif} alt="Loader" />
      </div>
    </div>
  )
}

export default Loader;
import AuthService from "../../../api/services/auth/Auth.service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { RegisterDto } from "../../../api/services/auth/Auth.dto";
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { setToken } from "../../../shared/helpers/token";

export const useSignUpForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [hasAgreePolicy, setHasAgreePolicy] = useState(false);

  const handleSignUp = async (data: RegisterDto) => {
    try {
      setLoading(true);
      const response = await AuthService.register({
        email: data.email,
        password: data.password,
      });
      if (response?.success) {
        setToken(response.data.token);
        toast.success("Signup Succcessfully");
        navigate("/send-email", { state: { email: data.email } });
      } else {
        console.error(response?.message);
      }
    } catch (error) {
      toast.error((error as Error)?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePolicyCheckbox = (e: CheckboxChangeEvent) => {
    setHasAgreePolicy(e.target.checked);
  }

  return {
    handleSignUp,
    loading,
    hasAgreePolicy,
    handleChangePolicyCheckbox
  };
};

export const COLOR = {
  primary: {
    50: '#FFEBE5',
    100: '#FFD7CC',
    200: '#FFAF99',
    300: '#FF8866',
    400: '#FF6033',
    500: '#FF3901',
  },
  secondary: {
    50: '#A1A1AB',
    100: '#868693',
    200: '#6C6C79',
    300: '#54545E',
    400: '#3C3C43',
    500: '#232327',
  },
  background: {
    white: '#FFFFFF',
    black: '#232327',
    primary: '#FE6033',
    secondary: '#73A0D7',
    overlay: '#232327',
    gray: '#F8F8FA',
    information: '#E7EFFC',
    success: '#E7F6E7',
    error: '#FAEBEB',
    warning: '#FFF9F0',
    disabled: '#F8F8F8',
  },
  border: {
    gray: '#C1C2C3',
    black: '#3C3C43',
    brand: '#FF3800',
    disabled: '#F0F0F1'
  },
  system: {
    information: '#0055F9',
    success: '#00991F',
    error: '#BE2F33',
    warning: '#F97800'
  },
  text: {
    white: '#FFFFFF',
    primary: '#0E1435',
    body: '#474B64',
    placeholder: '#888CAA',
    disabled: '#C8CCD2',
    gray: '#868693',
    subTitle: '#767B9E',
    brand: '#FF3800'
  },
  icon: {
    primary: '#0E1435',
    disabled: '#C8CCD2',
    body: '#474B64',
    gray: '##868693',
    brand: '#FF3800'
  }
};

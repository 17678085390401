import React, { useState, useContext, useEffect, useCallback } from 'react'
import Sidebar from '../components/Sidebar'
import FeedsContent from '../components/FeedsContent'
import ProfileSetupNudgeModal from '../components/ProfileSetupNudgeModal'
import { RoleContext } from "../context/currentRole";
import Api from "../services/api";
import PageTitle from '../components/PageTitle';


const Feeds = () => {
    const [showModal, setShowModal] = useState(false);
    const { currentRole } = useContext(RoleContext);

    // TODO Load entire user profile into context on login/switch
    const getIsModalSkipped = useCallback(async () => {
        const token = localStorage.getItem("token");
        let isModalSkipped = true;
        try {
            if (currentRole === "Startup") {
                const response = await Api.getStartupProfile(token);
                isModalSkipped = response.data.isModalSkipped;
            }
            else if (currentRole === "Investor") {
                const response = await Api.getInvestorProfile(token);
                isModalSkipped = response.data.isModalSkipped;
            }
            setShowModal(!isModalSkipped);
        }
        catch (error) {
            console.error(error.message);
        }
    }, [currentRole]);

    const handleSkip = async () => {
        const token = localStorage.getItem("token");
        try {
            await Api.isProfileModalSkipped({ type: currentRole.toLowerCase() }, token);
        }
        catch (error) {
            console.error(error.message);
        }
        setShowModal(false);
    };

    // Shows/hides modal on mount or role change
    useEffect(() => {
        getIsModalSkipped();
    }, [getIsModalSkipped]);

    return (
        <Sidebar>
            <PageTitle title="Home" />
            <FeedsContent />
            <ProfileSetupNudgeModal
                show={showModal}
                onSkip={handleSkip}
                onClose={() => setShowModal(false)}
            />
        </Sidebar>
    )
}

export default Feeds
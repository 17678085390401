import React from "react";
import { FavLogo } from "../utils/images";

const ProfileUpload = ({ src }) => {
  return (
    <div className="profile-image-startup">
      <img src={src ?? FavLogo} alt="" />
    </div>
  );
};

export default ProfileUpload;

import { logo } from "../../utils/images";
import React from "react";
import { Link } from "react-router-dom";

interface AngelLogoProps {
  type?: string;
}

const AngelLogo: React.FC<AngelLogoProps> = ({ type }) => {
  return (
    <Link
      to={type === "home" ? "/home" : "/"}
    >
      <img src={logo} alt="Logo" />
    </Link>
  );
};

export default AngelLogo;

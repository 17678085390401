import React, { createContext, useState } from "react";

export const ProfilesContext = createContext();

const ProfileState = (props) => {
  const [investors, setInvestors] = useState();
  const [startups, setStartups] = useState();
  const [followedProfiles, setFollowedProfiles] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState("");

  return (
    <ProfilesContext.Provider
      value={{
        investors,
        startups,
        setInvestors,
        setStartups,
        followedProfiles,
        setFollowedProfiles,
        searchTerm,
        setSearchTerm,
        sortBy,
        setSortBy,
      }}
    >
      {props.children}
    </ProfilesContext.Provider>
  );
};
export default ProfileState;

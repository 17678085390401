import React from "react";

const SelectedCategories = ({ categories }) => {
  return (
    <div className="d-flex gap-2 mt-3 flex-wrap align-items-center">
      {categories?.map((item) => (
        <div className="category-item">{item}</div>
      ))}
    </div>
  );
};

export default SelectedCategories;

import React, { useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Button, Col, Row } from "react-bootstrap";
import { HiOutlineArrowRight } from "react-icons/hi2";

const ImageCropModal = (props) => {
  const cropperRef = useRef(null);
  const [croppedImg, setCroppedImg] = useState(null);
  const [croppedImgFile, setCroppedImgFile] = useState(null);

  const onCrop = () => {
    const imageElement = cropperRef.current;
    if (imageElement) {
      const cropper = imageElement.cropper;
      if (cropper) {
        const croppedCanvas = cropper.getCroppedCanvas({});
        if (croppedCanvas) {
          const dataUrl = croppedCanvas.toDataURL();
          const byteString = atob(dataUrl.split(",")[1]);
          const mimeString = dataUrl.split(",")[0].split(":")[1].split(";")[0];
          const arrayBuffer = new ArrayBuffer(byteString.length);
          const uint8Array = new Uint8Array(arrayBuffer);
          for (let i = 0; i < byteString.length; i++) {
            uint8Array[i] = byteString.charCodeAt(i);
          }
          const blob = new Blob([uint8Array], { type: mimeString });

          const file = new File([blob], "cropped-image.png", {
            type: mimeString,
          });

          setCroppedImgFile(file);

          setCroppedImg(dataUrl);
        }
      }
    }
  };
  return (
    <div>
      {props?.showModal == true ? (
        <Modal
          show={props?.showModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ zIndex: "199999999" }}
        >
          <Modal.Body className="d-flex justify-content-center">
            <Cropper
              src={props?.imgURL}
              style={{ height: 500, width: "732px" }}
              guides={false}
              crop={onCrop}
              ref={cropperRef}
              viewMode={2}
              autoCropArea={1}
              checkOrientation={false}
              aspectRatio={1}
              cropBoxResizable={false}
              minCropBoxWidth={20}
              minCropBoxHeight={20}
              background={false}
            />
          </Modal.Body>
          <Modal.Footer>
            <Row className=" w-100 d-flex justify-content-center">
              <Col lg={6} md={12} xs={12}>
                <div className="d-flex gap-3 justify-content-end align-items-center mt-3">
                  <button
                    className=" view-button mt-0 update-button"
                    onClick={() => {
                      props?.onModalClose(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      props?.onSaveHandler(croppedImg);
                      props?.setCurrentPhoto(croppedImg);
                      props?.onModalClose();
                      props?.setFormValues((formValues) => ({
                        ...formValues,
                        [props?.name]: croppedImgFile,
                      }));
                      if (props?.isImageUpdated) props?.isImageUpdated(true);
                      // props.setImageError(false);
                    }}
                    className=" user-settings-btn  update-button mt-4 mb-4 text-white d-flex gap-2 justify-content-center align-items-center"
                  >
                    Save
                    <HiOutlineArrowRight className="right-arrow" />
                  </button>
                </div>
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ImageCropModal;

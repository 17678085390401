import { Col, Layout, Row, Grid, Typography } from "antd";
import AngelLogo from "../../../shared/components/AngelLogo";
import DigitalBox from "./DigitalBox";
import PageTitle from "../../../shared/components/PageTitle";
import Loader from "../../../shared/components/Loader";

interface Props {
  children: React.ReactNode;
  header: string;
  title: string;
  description?: string;
  loading?: boolean;
}

const AuthLayout = ({ children, header, title, description, loading }: Props) => {
  const { lg } = Grid.useBreakpoint();

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Layout className="min-h-[100vh]">
          <PageTitle title={header} />
          <Row>
            {lg && (
              <Col
                lg={12}
                className="tw-bg-[url('../../assets/images/digital-background.png')] tw-bg-cover tw-min-h-screen tw-flex tw-justify-center tw-items-center tw-bg-no-repeat"
              >
                <DigitalBox />
              </Col>
            )}
            <Col lg={12} md={24} xs={24} className="tw-bg-background-gray tw-bg-[url('../../assets/images/background-overlay.png')] tw-bg-cover tw-bg-no-repeat tw-bg-[right_center] tw-min-h-screen tw-h-full">
              <div className="create-account tw-space-y-8">
                <AngelLogo />
                <div>
                  <Typography.Title level={2} className="tw-mb-2 tw-font-bold tw-text-[40px] tw-text-text-primary">
                    {title}
                  </Typography.Title>

                  {description && (
                    <Typography.Paragraph className="tw-text-sm tw-text-text-primary">{description}</Typography.Paragraph>
                  )}
                </div>
                <div>
                 {children}
                </div>
              </div>
            </Col>
          </Row>
        </Layout>
      )}
    </>
  );
};

export default AuthLayout;

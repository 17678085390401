import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
  InternalAxiosRequestConfig,
  AxiosInstance,
} from "axios";
import { config } from "../configs";

const client: AxiosInstance = axios.create({
  baseURL: config?.ApiBaseURL,
  timeout: 15000, // 15 seconds timeout
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

client.interceptors.request.use(
  (req: InternalAxiosRequestConfig) => {
    if (req.headers && req.headers.Authorization) {
      req.headers.Authorization = `Bearer ${req.headers.Authorization}`;
    }

    if (req.headers) {
      req.headers["X-Request-ID"] = `${Date.now()}-${Math.random()}`;
    }

    return req;
  },
  (error: AxiosError) => {
    console.error("Request error:", error);
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error: AxiosError) => {
    const originalRequest = error.config as InternalAxiosRequestConfig & { _retry?: boolean; _retryCount?: number };
    const status = error.response?.status;

    // Handle token expiration and refresh
    if (status === 401 && !originalRequest._retry) {
      // Attempt to refresh token and retry the original request
    }

    console.error("Request failed:", error);

    return Promise.reject(error.response || error.message);
  }
);

// Request Wrapper with Request Cancellation
const request = async <U = {}>(options: AxiosRequestConfig): Promise<U> => {
  const controller = new AbortController();
  options.signal = controller.signal; // Attach the signal to support request cancellation

  const onSuccess = (response: AxiosResponse<U>): U => {
    return response.data;
  };

  const onError = (error: AxiosError & { data?: U }): Promise<never> => {
    return Promise.reject(error?.data || error.message);
  };

  try {
    const response = await client(options);
    return onSuccess(response);
  } catch (error) {
    return onError(error as AxiosError);
  }
};

const cancelRequest = (message = "Request cancelled by the user") => {
  const controller = new AbortController();
  controller.abort(message);
};

export { request, cancelRequest };

import React from 'react'
import ProfileUpload from './ProfileUpload';
import { dummyProfile,} from '../utils/images';
import { VscCalendar } from 'react-icons/vsc';
import { formatDate, getTimeAgo } from '../helpers';

const PostContent = ({post}) => {

    const newsFeed = JSON.parse(localStorage?.getItem("news-feed"))
    
    return (
        <div className='updates-section'>
            <div className="updates-image">
                <img src={newsFeed?.image ? newsFeed.image : dummyProfile} alt="feeds" />
            </div>
            <div className="profile-updates d-flex gap-5 align-items-center">
                <ProfileUpload
                    src={
                        post?.userImage === "" || !post?.userImage
                          ? dummyProfile
                          : post?.userImage
                            ? post?.userImage
                            : dummyProfile
                      }
                />
                <div className='detailed-content d-flex justify-content-between w-100'>
                    <div className='d-flex flex-column gap-4'>
                        <h2>
                        {post?.name}
                        </h2>
                        <span className="feed-time detail-feed mt-0 mb-1">
                            <VscCalendar />
                            <p>
                                {post?.createdAt && formatDate(post.createdAt)}
                            </p>
                        </span>
                    </div>
                    <p className='updates-detail-desc'>
                        {post?.createdAt && getTimeAgo(post.createdAt)}
                    </p>
                </div>
            </div>
            <div className="updates-content">
                <h1>{post?.title}</h1>
                <p>
                    {post?.description}
                </p>
            </div>
        </div>
    )
}

export default PostContent
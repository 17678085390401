import { LoginResponse } from "../../../api/services/auth/Auth.dto"

export const setLoginResponseToStorage = (data: LoginResponse['data'], isRemembered?: boolean) => {
  localStorage.setItem("user", JSON.stringify(data?.user));
  localStorage.setItem("isLoggedIn", 'true');
  localStorage.setItem("token", data.token);
  localStorage.setItem("userRole", JSON.stringify(data.role));
  localStorage.setItem("profileFlag", data?.user?.profileFlag ?? '');
  localStorage.setItem(
    "userName",
    data?.firstName
      ? data?.firstName + " " + data?.lastName
      : ''
  );
  localStorage.setItem(
    "profilePhoto",
    data?.profilePhoto ?? ''
  );
  localStorage.setItem("userId", data.user?.id);
  localStorage.setItem("companyName", data?.companyName ?? '');
  localStorage.setItem(
    "companyProfilePhoto",
    data?.companyProfilePhoto ?? ''
  );
  const role = data?.role?.includes("investor") ? "Investor" : "Startup";
  localStorage.setItem("currentRole", role);

  if (isRemembered) {
    localStorage.setItem("isRemembered", 'true');
  }
}
import React, { useCallback, useEffect, useState } from "react";
import ImageUploader from '../components/ImageUploader';
import { Container, Row, Col, Form, Spinner } from 'react-bootstrap';
import { BsCopy } from 'react-icons/bs';
import { ObjectUpload } from '../utils/images';
import { DragDrop } from '../utils/images';
import { RxCross2 } from 'react-icons/rx';
import FormLabel from '../components/FormLabel';
import MultiSelect from '../components/MultiSelect';
import InputField from '../components/InputField';
import Footer from '../components/Footer';
import TableInvestment from '../components/TableInvestment';
import Api from '../services/api';
import { toast } from 'react-toastify';
import {
  handleDragEnter,
  handleDragLeave,
  handleDragOver,
  handleKeyDown,
  linkedInUrl,
  validateHandle,
  websiteUrl,
} from '../helpers';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { countries, interestCategories, investmentStagesOptions } from '../constants';
import Sidebar from '../components/Sidebar';
import ImageCropModal from '../components/imageCropModal';
import ReactDatePicker from 'react-datepicker';
import { config } from '../configs';
import PageTitle from '../components/PageTitle';

const InvestorEditProfile = () => {
  const roleString = JSON.parse(localStorage.getItem("userRole"));

  const [previewImage, setPreviewImage] = useState(null);
  const [coverPhoto, setCoverPhoto] = useState(null);
  const [modalPreviewImage, setModalPreviewImage] = useState(null);

  const [loading, setLoading] = useState(false);
  const [investments, setInvestment] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [investmentError, setInvestmentError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isProfilePhotoUpdated, setIsProfilePhotoUpdated] = useState(false);
  const [isCoverPhotoUpdated, setIsCoverPhotoUpdated] = useState(false);
  const [newsFeedErrors, setNewsFeedErrors] = useState(false);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [newsPreviewImage, setNewsPreviewImage] = useState(null);
  const [newsData, setNewsData] = useState({
    title: "",
    description: "",
    file: null,
    role: "investor",
  });

  const [formValues, setFormValues] = useState({
    coverPhoto: null,
    profilePhoto: null,
    status: '',
    firstName: '',
    lastName: '',
    investorEmail: '',
    phoneNumber: '',
    country: '',
    city: '',
    interestCategories: [],
    about: '',
    investorStatus: '',
    investmentStages: [],
    mentor: '',
    websiteURL: '',
    linkedInLink: '',
    handle: '',
    isPrivateProfile: false,
  });

  const [formInvestment, setFormInvestment] = useState({
    companyName: '',
    dealDate: '',
    dealType: '',
    dealSize: '',
    industry: '',
    companyStage: '',
    leadInvestor: '',
  });

  const [errors, setErrors] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [investmentStages, setInvestmentStages] = useState([]);
  const [investmentLoader, setInvestmentLoader] = useState(false);
  const [copySuccess, setCopySuccess] = useState(true);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showCoverModal, setShowCoverModal] = useState(false);
  const [coverImage, setCoverImage] = useState(null);
  const [profileImage, setProfileImage] = useState(null);

  const token = localStorage.getItem("token");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    if (name === "handle") {
      setCopySuccess(false);
      const cleanedValue = value
        .replace(/\s+/g, "-")
        .replace(/[^a-z0-9-]+/g, "")
        .replace(/-+/g, "-")
        .toLowerCase();
      checkUniqueHandle(cleanedValue);
      setFormValues({
        ...formValues,
        handle: cleanedValue,
      });
    }
  };

  const handleDataChange = (e) => {
    const { name, value } = e.target;
    setNewsData({
      ...newsData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    setFormValues({
      ...formValues,
      isPrivateProfile: e?.target?.checked,
    });
  };

  const handleCopyClick = (e) => {
    if (copySuccess && formValues?.handle) {
      navigator.clipboard
        .writeText(`${config?.AppBaseURL}/investor/${formValues?.handle}`)
        .then(() => {
          toast.success("Profile URL copied!");
        })
        .catch((error) => {
          console.error("Error copying URL: ", error);
        });
    } else if (copySuccess && !formValues?.handle) {
      toast.error("Please enter user name");
    } else {
      toast.error("Make sure to save changes before copying.");
    }
  };

  const checkUniqueHandle = async (value) => {
    try {
      const response = await Api.checkHandle(token, {
        handle: value ?? formValues.handle,
        type: "investor",
      });
      if (response.success) {
        toast.success(response?.message);
      }
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };

  const getInvestorProfile = useCallback(async () => {
    try {

      const response = await Api.getInvestorProfile(token);
      if (response.success) {
        setSelectedOptions(response?.data?.interestCategories ?? []);
        setInvestmentStages(response?.data?.investmentStages ?? []);

        setPreviewImage(response?.data?.profilePhoto);
        setCoverPhoto(response?.data?.coverPhoto);
        setProfileImage(response?.data?.profilePhoto || null);
        setCoverImage(response?.data?.coverPhoto || null);

        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          coverPhoto: response?.data?.coverPhoto || null,
          profilePhoto: response?.data?.profilePhoto || null,
          firstName: response?.data?.firstName || "",
          lastName: response?.data?.lastName || "",
          investorEmail: response?.data?.investorEmail || "",
          phoneNumber: response?.data?.phoneNumber || "",
          country: response?.data?.country || "",
          city: response?.data?.city || "",
          address: response?.data?.address || "",
          interestCategories: response?.data?.interestCategories || [],
          about: response?.data?.about || "",
          investorStatus: response?.data?.investorStatus || "",
          investmentStages: response?.data?.investmentStages || [],
          mentor: response?.data?.mentor || "",
          websiteURL: response?.data?.socialLinks?.websiteUrl || "",
          linkedInLink: response?.data?.socialLinks?.linkedIn || "",
          handle: response?.data?.handle || "",
          isPrivateProfile: response?.data?.isPrivateProfile,
        }));

        // Load phone validation
        const phone = response?.data?.phoneNumber;
        setIsPhoneNumberValid(isValidPhoneNumber(phone));
      }
    }
    catch (error) {
      // Handle when someone clicks 'Create Investor Profile'
      // TODO Don't 404, use proper endpoint to handle new investor
      if (error.status === 404) {
        // Set default values
        // TODO Remove user fields from investor component
        // TODO These user fields aren't always loaded, fix this
        const user = JSON.parse(localStorage.getItem("user"));
        setFormValues((prevFormValues)=>({
          // TODO Form will return "undefined" if "" is not loaded from above, fix this
          ...prevFormValues,
          firstName: user?.firstName,
          lastName: user?.lastName,
          handle: user?.handle,
          phoneNumber: user?.phoneNumber,
          country: user?.country,
          // TODO User profile picture
        }));
        if(user?.phoneNumber){
          setIsPhoneNumberValid(isValidPhoneNumber(user.phoneNumber));
        }
        return;
      }
      toast.error(error.data.message);
    }
  }, [token]);

  const getInvestment = useCallback(async (page, rowsPerPage) => {
    try {
      const response = await Api.getInvestment(token, page, rowsPerPage);
      if (response.success) {
        setInvestment(response);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }, [token]);

  const handleCancelImage = (e) => {
    e.stopPropagation();
    setNewsPreviewImage(null);
    document.getElementById("newsFeedImage").value = "";

    const fileInput = document.getElementById("newsFeedImage");
    fileInput.disabled = true;

    setTimeout(() => {
      fileInput.disabled = false;
      fileInput.value = "";
    }, 1000);
  };

  const handleImageChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];
    const maxSizeInBytes = 2 * 1024 * 1024;

    if (file) {
      const coverImageSize = 5 * 1024 * 1024;
      if (name === "coverImage" && file?.size > coverImageSize) {
        toast.error(
          "Image size is larger than 5MB. Please upload a smaller file."
        );
        e.target.value = null;
        return;
      } else if (name !== "coverImage" && file.size > maxSizeInBytes) {
        toast.error(
          "Image size is larger than 2MB. Please upload a smaller file."
        );
        e.target.value = null;
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        if (name === "coverImage") {
          setModalPreviewImage(reader.result);
          setShowCoverModal(true);
        } else if (name === "file") {
          setNewsPreviewImage(reader.result);
          setNewsData((newsFeed) => ({
            ...newsFeed,
            file: file,
          }));
        } else {
          setModalPreviewImage(reader.result);

          setShowProfileModal(true);
        }
      };
      reader.readAsDataURL(file);

      setTimeout(() => {
        e.target.value = null;
      }, 0);
    }
  };

  const handleRemove = () => {
    setFormValues((formValues) => ({
      ...formValues,
      coverPhoto: null,
    }));
    setCoverPhoto(null);
    setIsCoverPhotoUpdated(true);
  };

  const handleDrop = (e, name) => {
    const maxSizeInBytes = 2 * 1024 * 1024;

    e.preventDefault();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    if (file) {
      const coverImageSize = 5 * 1024 * 1024;
      if (name === "coverImage" && file?.size > coverImageSize) {
        toast.error(
          "Image size is larger than 5MB. Please upload a smaller file."
        );
        e.target.value = null;
        return;
      } else if (name !== "coverImage" && file.size > maxSizeInBytes) {
        toast.error(
          "Image size is larger than 2MB. Please upload a smaller file."
        );
        e.target.value = null;
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        if (name === "coverImage") {
          setModalPreviewImage(reader.result);
          setShowCoverModal(true);
        } else if (name === "file") {
          setNewsPreviewImage(reader.result);
          setNewsData((newsFeed) => ({
            ...newsFeed,
            file: file,
          }));
        } else {
          setModalPreviewImage(reader.result);
          setShowProfileModal(true);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      if (
        !formValues.firstName ||
        !formValues.lastName ||
        !formValues.handle ||
        !formValues.phoneNumber ||
        !formValues.country ||
        !isPhoneNumberValid ||
        (formValues.websiteURL && !websiteUrl(formValues.websiteURL)) ||
        (formValues.linkedInLink && !linkedInUrl(formValues.linkedInLink)) ||
        (formValues.handle && !validateHandle(formValues?.handle))
      ) {
        setErrors(true);
        return false;
      }

      const formData = new FormData();
      if (isCoverPhotoUpdated) {
        formData.append("coverPhoto", formValues?.coverPhoto);
        if (!coverPhoto) {
          formData.append("coverRemoved", true);
        }
      } else {
        formData.append("coverPhoto", null);
      }
      if (isProfilePhotoUpdated) {
        formData.append("profilePhoto", formValues?.profilePhoto);
      } else {
        formData.append("profilePhoto", null);
      }

      // TODO The backend can handle an object/array, remove JSON.stringify & JSON.parse
      const interestCategories = formValues?.interestCategories ? JSON.stringify(formValues?.interestCategories) : null;
      const investmentStages = formValues?.investmentStages ? JSON.stringify(formValues?.investmentStages) : null;

      formData.append("firstName", formValues?.firstName);
      formData.append("lastName", formValues?.lastName);
      formData.append("investorEmail", formValues?.investorEmail);
      formData.append("phoneNumber", formValues?.phoneNumber);
      formData.append("country", formValues?.country);
      formData.append("city", formValues?.city);
      formData.append("address", formValues?.address);
      formData.append("websiteUrl", formValues.websiteURL);
      formData.append("linkedIn", formValues.linkedInLink);
      formData.append("interestCategories", interestCategories);
      formData.append("about", formValues?.about);
      formData.append("investorStatus", formValues?.investorStatus);
      formData.append("investmentStages", investmentStages);
      formData.append("mentor", formValues?.mentor);
      formData.append("joinDate", formValues?.joinDate);
      formData.append("isPrivateProfile", formValues?.isPrivateProfile);
      formData.append("handle", formValues?.handle);
      const response = await Api.updateInvestorProfile(formData, token);
      if (response?.success) {
        localStorage.setItem(
          "userName",
          formValues?.firstName + " " + formValues?.lastName
        );
        if (roleString?.length < 2 && roleString?.includes("startup")) {
          localStorage.setItem("profileFlag", 1);
          localStorage.setItem(
            "userRole",
            JSON.stringify(["investor", "startup"])
          );
        }
        localStorage.setItem("profilePhoto", previewImage);
        setCopySuccess(true);
        toast.success("Investor Profile Updated Succcessfully");
      } else {
        toast.error(response?.data?.message);
      }
    } catch (e) {
      toast.error(e?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleInvestmentChange = (e) => {
    const { name, value } = e.target;
    setFormInvestment({
      ...formInvestment,
      [name]: value,
    });
  };

  const handleInvestmentDateChange = (date) => {
    setFormInvestment({
      ...formInvestment,
      dealDate: date,
    });
  };

  const handlePhoneChange = (value) => {
    if (value) {
      const isValid = isValidPhoneNumber(value);
      setIsPhoneNumberValid(isValid);
    }
    setFormValues((formValues) => ({
      ...formValues,
      phoneNumber: value,
    }));
  };

  const handleInvestmentSubmit = async (e) => {
    e.preventDefault();
    try {
      setInvestmentLoader(true);
      if (!formInvestment.companyName) {
        setInvestmentError(true);
        return true;
      }
      const response = await Api.createInvestment(formInvestment, token);
      if (response?.success) {
        toast.success("Investment added Succcessfully", {
          pauseOnFocusLoss: false,
        });
        setFormInvestment({
          companyName: "",
          dealDate: "",
          dealType: "",
          dealSize: "",
          industry: "",
          companyStage: "",
          leadInvestor: "",
        });
        setInvestmentError(false);

        getInvestment(1, 5);
      } else {
        toast.error(response?.data?.message);
      }
    } catch (e) {
      toast.error(e?.data?.message);
    } finally {
      setInvestmentLoader(false);
    }
  };

  const handleNewsFeedSubmit = async () => {
    if (!newsData?.title || !newsData?.description || !newsData?.file) {
      setNewsFeedErrors(true);
      return;
    }
    try {
      setLoader(true);
      const formDataa = new FormData();
      for (const key in newsData) {
        formDataa.append(key, newsData[key]);
      }
      const response = await Api.addNewsFeed(formDataa, token);
      if (response?.success) {
        setNewsData((newsFeed) => ({
          ...newsFeed,
          title: "",
          description: "",
          file: null,
          role: "investor",
        }));
        setNewsPreviewImage(null);
        setNewsFeedErrors(false);
        toast.success("News feed added successfully");
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error(error?.data?.message);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getInvestorProfile();
    getInvestment(1, 5);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [getInvestorProfile, getInvestment]);

  return (
    <Sidebar>
      <PageTitle title="Edit Profile" />
      <div className="main-content dashboard-main">
        <div className="dash-content">
          <ImageCropModal
            showModal={showCoverModal}
            imgURL={modalPreviewImage}
            onSaveHandler={setCoverPhoto}
            currentPhoto={coverImage}
            setCurrentPhoto={setCoverImage}
            name="coverPhoto"
            setFormValues={setFormValues}
            isImageUpdated={setIsCoverPhotoUpdated}
            onModalClose={() => {
              setShowCoverModal(false);
            }}
          />
          <ImageUploader
            name="coverImage"
            handleImageChange={handleImageChange}
            previewImage={coverPhoto}
            setPreviewImage={setCoverPhoto}
            handleDragOver={handleDragOver(setIsDragging)}
            handleDragEnter={handleDragEnter(setIsDragging)}
            handleDragLeave={handleDragLeave(setIsDragging)}
            handleDrop={(e) => handleDrop(e, "coverImage")}
            handleRemove={handleRemove}
          />
          <Container fluid className="public-profile">
            <div className="status-field d-flex gap-5">
              <div className="status-craft d-flex flex-column">
                <div className="profile-image-edit">
                  <ImageCropModal
                    showModal={showProfileModal}
                    imgURL={modalPreviewImage}
                    onSaveHandler={setPreviewImage}
                    currentPhoto={profileImage}
                    setCurrentPhoto={setProfileImage}
                    name="profilePhoto"
                    setFormValues={setFormValues}
                    isImageUpdated={setIsProfilePhotoUpdated}
                    onModalClose={() => {
                      setShowProfileModal(false);
                    }}
                  />
                  <div
                    className={`drag-drop-container ${isDragging ? "dragging" : ""
                      }`}
                    onDragOver={handleDragOver(setIsDragging)}
                    onDragEnter={handleDragEnter(setIsDragging)}
                    onDragLeave={handleDragLeave(setIsDragging)}
                    onDrop={(e) => handleDrop(e, "profilePhoto")}
                  >
                    <label
                      htmlFor="file-upload"
                      className="object-upload text-center pt-5 d-flex flex-column justify-content-center align-items-center"
                    >
                      <input
                        type="file"
                        id="file-upload"
                        style={{ display: "none" }}
                        name="profilePhoto"
                        onChange={(e) => handleImageChange(e)}
                        accept="image/*"
                        multiple
                      />

                      <img
                        src={previewImage ? previewImage : ObjectUpload}
                        alt=""
                        className={previewImage ? "preview-image" : ""}
                      />
                      {!previewImage && (
                        <p className="drop-desc mb-0">
                          Drop your image <br />
                          here, or{" "}
                          <span className="secondary-text secondary-desc browse-text">
                            browse
                          </span>
                        </p>
                      )}
                    </label>
                  </div>
                </div>
                <label
                  htmlFor="public-profile-checkbox"
                  className="public-private d-flex align-items-center gap-2"
                >
                  <input
                    type="checkbox"
                    id="public-profile-checkbox"
                    className=" checkbox-box private-checkbox"
                    checked={formValues?.isPrivateProfile}
                    onChange={(e) => handleCheckboxChange(e)}
                  />
                  <FormLabel
                    className="set-status mb-0"
                    labelText="Make profile private"
                  />
                </label>
              </div>
            </div>
          </Container>
          <Container fluid className="public-profile">
            <Row className="startup-points pb-5 justify-content-between">
              <Col lg={6} md={12} xs={12}>
                <Row className="startup-name">
                  <Col lg={6} md={12} xs={12}>
                    <div className="form-group d-flex flex-column gap-2">
                      <FormLabel
                        className="form-label"
                        labelText="First Name"
                        required={true}
                      />
                      <InputField
                        className="form-input"
                        placeholder="First Name"
                        type="text"
                        name="firstName"
                        value={formValues.firstName}
                        onChange={handleChange}
                      />

                      {errors && !formValues.firstName && (
                        <p className="error-message secondary-text mb-0">
                          Please enter your first name
                        </p>
                      )}
                    </div>
                  </Col>
                  <Col lg={6} md={12} xs={12}>
                    <div className="form-group d-flex flex-column gap-2">
                      <FormLabel
                        className="form-label"
                        labelText="Last Name"
                        required={true}
                      />
                      <InputField
                        className="form-input"
                        placeholder="Last Name"
                        type="text"
                        name="lastName"
                        value={formValues.lastName}
                        onChange={handleChange}
                      />
                      {errors && !formValues.lastName && (
                        <p className="error-message secondary-text mb-0">
                          Please enter your last name
                        </p>
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={6} md={12} xs={12}>
                <div className="form-group d-flex flex-column gap-2">
                  <FormLabel
                    className="form-label align-items-center"
                    labelText="Profile URL"
                    required={true}
                    helperText="No special characters allowed, spaces in names will convert to dashes."
                  />
                  <div className="form-input  profile-input align-items-center d-flex gap-2">
                    <p className="wrapper-text">
                      {config?.AppBaseURL}/investor
                    </p>
                    <InputField
                      className="form-input profile-input select-form"
                      placeholder="Enter User Name"
                      type="text"
                      name="handle"
                      value={formValues.handle}
                      onChange={handleChange}
                    />
                    <div className="d-flex gap-2 justify-content-end">
                      <div className="tooltip-container">
                        <button
                          className="secondary-text mb-0 profile-copy-icon"
                          disabled={!formValues?.handle}
                          onClick={(e) => handleCopyClick(e)}
                        >
                          <BsCopy
                            className={
                              !formValues?.handle
                                ? "handbook-select disabled-icon"
                                : "handbook-select"
                            }
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  {errors && !formValues.handle ? (
                    <p className="error-message secondary-text mb-0">
                      Please enter user name
                    </p>
                  ) : errors && !validateHandle(formValues?.handle) ? (
                    <p className="error-message secondary-text mb-0">
                      User name contain only alphabets and numbers
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
              <Col lg={6} md={12} xs={12}>
                <div className="form-group d-flex flex-column gap-2">
                  <FormLabel
                    className="form-label"
                    labelText="Email Address"
                    helperText="This email address will appear on your profile"
                  />
                  <InputField
                    className="form-input"
                    placeholder="Enter email address"
                    type="email"
                    name="investorEmail"
                    value={formValues.investorEmail}
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col lg={6} md={12} xs={12}>
                <div className="form-group d-flex flex-column gap-2">
                  <FormLabel
                    className="form-label"
                    labelText="Phone Number"
                    required={true}
                  />
                  <div className="input-field form-input d-flex gap-2">
                    <PhoneInput
                      defaultCountry="HK"
                      placeholder="Enter phone number"
                      addInternationalOption={false}
                      name="phoneNumber"
                      value={formValues?.phoneNumber}
                      onChange={handlePhoneChange}
                    />
                  </div>

                  {errors && !formValues.phoneNumber ? (
                    <p className="error-message secondary-text mb-0">
                      Please enter your phone number
                    </p>
                  ) : errors && !isPhoneNumberValid ? (
                    <p className="error-message secondary-text mb-0">
                      Please enter valid phone number
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
              <Col lg={6} md={12} xs={12}>
                <div className="form-group d-flex flex-column gap-2">
                  <FormLabel
                    className="form-label"
                    labelText="Country"
                    required={true}
                  />
                  <select
                    id="country"
                    name="country"
                    value={formValues.country}
                    onChange={handleChange}
                    className="form-input form-select-select"
                  >
                    <option value="" disabled selected>
                      Select a country
                    </option>
                    {countries.map((country, index) => (
                      <option key={index} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                  {errors && !formValues.country && (
                    <p className="error-message secondary-text mb-0">
                      Please enter country
                    </p>
                  )}
                </div>
              </Col>
              <Col lg={6} md={12} xs={12}>
                <div className="form-group d-flex flex-column gap-2">
                  <FormLabel className="form-label" labelText="City " />
                  <InputField
                    className="form-input"
                    placeholder="Enter City"
                    type="text"
                    name="city"
                    value={formValues.city}
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col lg={6} md={12} xs={12}>
                <div className="form-group d-flex flex-column gap-2">
                  <FormLabel
                    className="form-label"
                    labelText="Address"
                  // required={true}
                  />
                  <InputField
                    name="address"
                    className="form-input"
                    placeholder="Enter Address"
                    value={formValues.address}
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col lg={6} md={12} xs={12}>
                <div className="form-group d-flex flex-column gap-2">
                  <FormLabel
                    className="form-label"
                    labelText="Categories of Interest"
                  />
                  <MultiSelect
                    value={formValues?.interestCategories}
                    onChange={handleChange}
                    name="interestCategories"
                    setFormInfo={setFormValues}
                    formInfo={formValues}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                    formName="interestCategories"
                    type="multiple"
                    options={interestCategories}
                  />
                </div>
              </Col>
            </Row>
          </Container>
          <Container fluid className="public-profile mt-4 startup-points pb-5">
            <Row className="profile-row justify-content-between">
              <Col lg={12} md={12} xs={12}>
                <div className="form-group d-flex flex-column gap-2">
                  <FormLabel
                    className="form-label"
                    labelText="Tell other Angelflow members a little more about yourself (max 200 words)"
                  />
                  <textarea
                    name="about"
                    id=""
                    cols="30"
                    rows="10"
                    value={formValues?.about}
                    onChange={handleChange}
                    maxLength="200"
                    className="form-input about-company"
                  ></textarea>
                </div>
              </Col>
              <Col lg={6} md={12} xs={12}>
                <div className="form-group d-flex flex-column gap-2">
                  <FormLabel
                    className="form-label"
                    labelText="Investor Status"
                  />
                  <select
                    name="investorStatus"
                    className="form-input form-select-select"
                    onChange={handleChange}
                    value={formValues.investorStatus}
                  >
                    <option value="" disabled hidden>
                      Select option
                    </option>
                    <option value="Actively seeking new investments">
                      Actively seeking new investments
                    </option>
                    <option value="Open to considering new investment">
                      Open to considering new investment
                    </option>
                    <option value="Taking a break / Not interested in new investments">
                      Taking a break / Not interested in new investments
                    </option>
                  </select>
                </div>
              </Col>
              <Col lg={6} md={12} xs={12}>
                <div className="form-group d-flex flex-column gap-2">
                  <FormLabel
                    className="form-label"
                    labelText="Primary investment stages"
                  />

                  <MultiSelect
                    value={formValues.investmentStages}
                    onChange={handleChange}
                    name="investmentStages"
                    setFormInfo={setFormValues}
                    formInfo={formValues}
                    selectedOptions={investmentStages}
                    setSelectedOptions={setInvestmentStages}
                    formName="investmentStages"
                    type="multiple"
                    options={investmentStagesOptions}
                    label={"Select investment stages"}
                  />
                </div>
              </Col>
              <Col lg={6} md={12} xs={12}>
                <div className="form-group d-flex flex-column gap-2">
                  <FormLabel
                    className="form-label"
                    labelText="Are you interested in providing mentorship to founders?"
                  />
                  <select
                    name="mentor"
                    className="form-input form-select-select"
                    onChange={handleChange}
                    value={formValues.mentor}
                  >
                    <option value="" disabled hidden>
                      Select option
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
              </Col>
              <Col lg={6} md={12} xs={12}>
                <div className="form-group d-flex flex-column gap-2">
                  <FormLabel
                    className="form-label"
                    labelText="Website (if any)"
                  />
                  <InputField
                    className="form-input"
                    placeholder="Enter Website URL"
                    type="text"
                    name="websiteURL"
                    value={formValues?.websiteURL}
                    onChange={handleChange}
                  />
                  {errors &&
                    formValues.websiteURL &&
                    !websiteUrl(formValues.websiteURL) && (
                      <p className="error-message secondary-text mb-0">
                        Please enter valid website URL
                      </p>
                    )}
                </div>
              </Col>
              <Col lg={6} md={12} xs={12}>
                <div className="form-group d-flex flex-column gap-2">
                  <FormLabel className="form-label" labelText="Linkedin" />
                  <InputField
                    className="form-input"
                    placeholder="Linkedin URL"
                    type="text"
                    name="linkedInLink"
                    value={formValues?.linkedInLink}
                    onChange={handleChange}
                  />
                  {errors &&
                    formValues.linkedInLink &&
                    !linkedInUrl(formValues.linkedInLink) && (
                      <p className="error-message secondary-text mb-0">
                        Please enter valid linkedIn URL
                      </p>
                    )}
                </div>
              </Col>
              <Col lg={6} md={12} xs={12}>
                <div className="updates-buttons updated-buttons  d-flex justify-content-end align-items-center gap-3">
                  <button className="view-button update-btn mt-0">
                    Cancel
                  </button>
                  <button
                    className="primary-btn update-btn d-flex align-items-center justify-content-center gap-2"
                    onClick={(e) => handleSubmit(e)}
                  >
                    Update
                    {loading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      <></>
                    )}
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
          <Container
            fluid
            className="public-profile mt-0 startup-points pb-4 mb-4"
          >
            <h1 className="profile-heading mt-4">
              Investments{" "}
              <span className="secondary-text slash-trending">_</span>
            </h1>
            <p className="chat-desc investment-subheading mt-3">
              Share details of any previous investments you've made in the past.
            </p>
            <Form
              onSubmit={handleInvestmentSubmit}
              className="form-investments pb-4 "
            >
              <Row className="profile-row mt-4 justify-content-between">
                <Col lg={6} md={12} xs={12}>
                  <div className="form-group d-flex flex-column gap-2">
                    <FormLabel
                      className="form-label"
                      labelText="Company Name"
                      required={true}
                    />
                    <InputField
                      className="form-input"
                      placeholder="Enter Company Name"
                      type="text"
                      name="companyName"
                      value={formInvestment.companyName}
                      onChange={handleInvestmentChange}
                    />
                    {investmentError && !formInvestment.companyName && (
                      <p className="error-message secondary-text mb-0">
                        Please enter company name
                      </p>
                    )}
                  </div>
                </Col>
                <Col lg={6} md={12} xs={12}>
                  <div className="form-group d-flex flex-column gap-2">
                    <FormLabel className="form-label" labelText="Deal Date" />
                    <ReactDatePicker
                      className="form-input date-input"
                      selected={
                        formInvestment.dealDate
                          ? new Date(formInvestment.dealDate)
                          : null
                      }
                      onChange={handleInvestmentDateChange}
                      dateFormat="MM/yyyy"
                      placeholderText="mm | yyyy"
                      showMonthYearPicker
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={15}
                    />
                  </div>
                </Col>
                <Col lg={6} md={12} xs={12}>
                  <div className="form-group d-flex flex-column gap-2">
                    <FormLabel className="form-label" labelText="Deal Type" />
                    <select
                      name="dealType"
                      className="form-input form-select-select"
                      value={formInvestment.dealType}
                      onChange={handleInvestmentChange}
                    >
                      <option value="" disabled hidden>
                        Select Type
                      </option>
                      <option value="Safe">Safe</option>
                      <option value="Convertible">Convertible</option>
                      <option value="Preferred Stock">Preferred Stock</option>
                      <option value="Common Stock">Common Stock</option>
                      <option value="Ideation">Ideation</option>
                      <option value="Debt Financing">Debt Financing</option>
                    </select>
                  </div>
                </Col>
                <Col lg={6} md={12} xs={12}>
                  <div className="form-group d-flex flex-column gap-2">
                    <FormLabel
                      className="form-label"
                      labelText="Deal size (how much the startup raised in this round)"
                    />
                    <InputField
                      className="form-input"
                      placeholder="Enter Deal Size"
                      type="text"
                      name="dealSize"
                      onKeyDown={handleKeyDown}
                      value={formInvestment.dealSize}
                      onChange={handleInvestmentChange}
                    />
                  </div>
                </Col>
                <Col lg={6} md={12} xs={12}>
                  <div className="form-group d-flex flex-column gap-2">
                    <FormLabel className="form-label" labelText="Industry" />
                    <select
                      name="industry"
                      className="form-input form-select-select"
                      onChange={handleInvestmentChange}
                      value={formInvestment.industry}
                    >
                      <option value="" selected>
                        {"Select Industry"}
                      </option>
                      {interestCategories?.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
                <Col lg={6} md={12} xs={12}>
                  <div className="form-group d-flex flex-column gap-2">
                    <FormLabel
                      className="form-label"
                      labelText="Company Stage"
                    />
                    <select
                      name="companyStage"
                      className="form-input form-select-select"
                      value={formInvestment.companyStage}
                      onChange={handleInvestmentChange}
                    >
                      <option value="" disabled hidden>
                        Select Stage
                      </option>
                      <option value="Pre-seed">Pre-seed</option>
                      <option value="Seed">Seed</option>
                      <option value="Series A">Series A</option>
                      <option value="Series B">Series B</option>
                      <option value="Ideation">Ideation</option>
                    </select>
                  </div>
                </Col>
                <Col lg={6} md={12} xs={12}>
                  <div className="form-group d-flex flex-column gap-2">
                    <FormLabel
                      className="form-label"
                      labelText="Lead Investor "
                    />
                    <InputField
                      className="form-input"
                      placeholder="Investor Name"
                      type="text"
                      name="leadInvestor"
                      value={formInvestment.leadInvestor}
                      onChange={handleInvestmentChange}
                    />
                  </div>
                </Col>
                <Col lg={6} md={12} xs={12}>
                  <div className="form-group d-flex flex-column gap-2 pt-3">
                    <div className="updates-buttons d-flex justify-content-end align-items-center mt-4 gap-3">
                      <button className="view-button update-btn mt-0">
                        Cancel
                      </button>
                      <button type="submit" className="primary-btn update-btn">
                        Add{" "}
                        {investmentLoader ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          <></>
                        )}
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
          </Container>
          <Container
            fluid
            className="public-profile mt-4 startup-points pb-4 pt-2"
          >
            <TableInvestment
              rows={investments}
              type="edit-investment"
              pagination={investments?.pagination}
              getInvestment={getInvestment}
              setInvestment={setInvestment}
            />
          </Container>

          <Container fluid className="public-profile mt-4">
            <div className="feed-footer">
              <Row className="post-feeds">
                <Col lg={12} md={12} xs={12}>
                  <div className="profile-category d-flex justify-content-between align-items-center">
                    <h1 className="profile-heading">
                      News/Updates
                      <span className="secondary-text slash-trending">_</span>
                    </h1>
                  </div>
                </Col>
                <Col lg={6} md={12} xs={12}>
                  <div
                    name="file"
                    className={`drag-drop-container ${isDragging ? "profile-drag dragging" : "profile-drag"
                      }`}
                    onDragOver={handleDragOver(setIsDragging)}
                    onDragEnter={handleDragEnter(setIsDragging)}
                    onDragLeave={handleDragLeave(setIsDragging)}
                    onDrop={(e) => handleDrop(e, "file")}
                  >
                    {" "}
                    <input
                      type="file"
                      id="newsFeedImage"
                      accept="image/*"
                      name="file"
                      onChange={(e) => handleImageChange(e)}
                      style={{ display: "none" }}
                    />
                    <label
                      htmlFor="newsFeedImage"
                      className="text-center position-relative"
                    >
                      {newsPreviewImage ? (
                        <>
                          <img
                            src={newsPreviewImage ? newsPreviewImage : DragDrop}
                            alt="Drag and drop icon"
                            className=" feed-img mt-0 mb-0 "
                          />
                          <div className="cancel-img d-flex align-items-center justify-content-center">
                            <RxCross2
                              className="cancel-img-icon"
                              onClick={handleCancelImage}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <img
                            src={DragDrop}
                            alt="Drag and drop icon"
                            className="feed-img mt-0 mb-0"
                          />
                          <p className="desc-cover">
                            Drop your image here, or&nbsp;
                            <span className="secondary-text browse-text">
                              browse
                            </span>
                          </p>
                        </>
                      )}
                    </label>
                  </div>
                  {newsFeedErrors && !newsData.file && (
                    <p className="error-message secondary-text mb-0 mt-2">
                      Please select image
                    </p>
                  )}
                </Col>
                <Col
                  lg={6}
                  md={12}
                  xs={12}
                  className="profile-fields profile-fields-feed d-flex flex-column gap-4 mt-4"
                >
                  <div className=" form-group d-flex flex-column gap-2 mt-2">
                    <FormLabel
                      className="form-label"
                      labelText="Title"
                      required={true}
                    />
                    <InputField
                      className="form-input"
                      placeholder="Enter Name"
                      type="text"
                      name="title"
                      value={newsData?.title}
                      onChange={(e) => handleDataChange(e)}
                    />
                    {newsFeedErrors && !newsData.title && (
                      <p className="error-message secondary-text mb-0">
                        Please enter title
                      </p>
                    )}
                  </div>
                  <div className="form-group d-flex flex-column gap-2 mt-1">
                    <FormLabel
                      className="form-label"
                      labelText="Description"
                      required={true}
                    />
                    <textarea
                      id=""
                      cols="30"
                      rows="10"
                      className="form-input about-text"
                      name="description"
                      value={newsData?.description}
                      placeholder="Enter Description"
                      onChange={(e) => handleDataChange(e)}
                    ></textarea>
                    {newsFeedErrors && !newsData.description && (
                      <p className="error-message secondary-text mb-0">
                        Please enter description
                      </p>
                    )}
                  </div>
                </Col>
                <Col lg={12} md={12} xs={12}>
                  <div className="d-flex justify-content-end align-items-center gap-3 mt-4 mb-2">
                    <button
                      className="primary-btn update-button"
                      onClick={(e) => handleNewsFeedSubmit(e)}
                    >
                      Post{" "}
                      {loader ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <></>
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <div className="mt-5">
          <Footer className="simple-container" />
        </div>
      </div>
    </Sidebar>
  );
};

export default InvestorEditProfile;

import AuthService from "../../../api/services/auth/Auth.service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useState, useContext } from "react";
import { RoleContext } from "../../../context/currentRole";
import { LoginDto } from "../../../api/services/auth/Auth.dto";
import { setLoginResponseToStorage } from "../helpers/setLoginResponseToStorage";

export const useLoginForm = () => {
  const navigate = useNavigate();
  const { setCurrentRole } = useContext(RoleContext);
  const [loading, setLoading] = useState(false);

  const handleLogin = async (data: LoginDto) => {
    try {
      setLoading(true);
      const response = await AuthService.login({
        email: data.email,
        password: data.password,
      });
      if (response?.success) {
        setLoginResponseToStorage(response?.data, data?.rememberMe);

        const role = response?.data?.role?.includes("investor") ? "Investor" : "Startup";
        setCurrentRole(role);

        if (
          response?.data?.role?.includes("startup") &&
          !response?.data?.companyName &&
          !response?.data?.user?.isSkipped
        ) {
          navigate("/profile-setup", { state: { stepper: 2 } });
        } else if (response?.data?.role) {
          navigate("/home");
        } else {
          navigate("/profile-setup");
        }
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error((error as Error)?.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    handleLogin,
    loading,
  };
};

import React from "react";
import { RxCross1 } from "react-icons/rx";

const MultiSelect = ({
  value,
  name,
  selectedOptions,
  setSelectedOptions,
  setFormInfo,
  formInfo,
  formName,
  label,
  type,
  options,
}) => {
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;

    if (type === "single") {
      setSelectedOptions([selectedValue]);
      setFormInfo((prevErrors) => ({
        ...prevErrors,
        [formName]: selectedValue,
      }));
    } else if (type === "multiple") {
      if (!selectedOptions?.includes(selectedValue)) {
        const newSelectedOptions = [...selectedOptions, selectedValue];
        setSelectedOptions(newSelectedOptions);
        setFormInfo((prevErrors) => ({
          ...prevErrors,
          [formName]: newSelectedOptions,
        }));
      }
    }
  };

  const handleRemoveOption = (removedOption) => {
    const updatedOptions = selectedOptions.filter(
      (option) => option !== removedOption
    );
    setSelectedOptions(updatedOptions);
    setFormInfo((prevErrors) => ({
      ...prevErrors,
      [formName]: updatedOptions,
    }));
  };

  return (
    <div className="multi-select-container">
      <select
        name={name}
        id=""
        value={type === "multiple" ? "Select categories" : "Select category"}
        onChange={handleSelectChange}
        className="form-input form-select-select"
      >
        <option value="" selected>
          {label ? label : "Select categories"}
        </option>
        {options?.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <div className="selected-options">
        {selectedOptions &&
          selectedOptions?.map((option) => (
            <div key={option} className="selected-option mt-3">
              {option}
              <RxCross1
                className="remove-option"
                onClick={() => handleRemoveOption(option)}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default MultiSelect;

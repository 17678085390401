import React from "react";
import { logo } from "../utils/images/index";
import { Link } from "react-router-dom";

const AngelLogo = ({ className, type, name }) => {
  return (
    <Link
      to={type === "home" ? "/home" : "/"}
      className={className ? className : "platform-logo"}
    >
      <img src={logo} alt="Logo" className={name ? name : " "} />
    </Link>
  );
};

export default AngelLogo;

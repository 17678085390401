import React from "react";
import { CoverPhoto } from "../utils/images";

const CoverImage = ({ src, type }) => {
  return (
    <div
      className={
        type === "investor-profile" || type === "startup-profile"
          ? "cover-img"
          : "cover-photo"
      }
    >
      {src ? (
        <img src={src} alt="" />
      ) : (
        <img src={CoverPhoto} alt="" />
      )}
    </div>
  );
};

export default CoverImage;

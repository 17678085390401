import React from 'react';
import { Gif } from '../utils/images';

const LoaderGif = () => {

    return (
        <div className="loader-wrapper">
            <div className="loader-container">
                <img src={Gif} alt="Loader" />
            </div>
        </div>
    )
}

export default LoaderGif
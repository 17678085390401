import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import { ErrorGif } from "../utils/images";
import { useNavigate } from "react-router";
import { RoleContext } from "../context/currentRole";

const ProfileSetupNudgeModal = ({ show, onClose, onSkip }) => {
  const { currentRole } = useContext(RoleContext);

  const navigate = useNavigate();
  const role = JSON.parse(localStorage?.getItem("userRole"));
  return (
    <Modal show={show} onHide={onClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header className="justify-content-center">
        <Modal.Title>
          <img src={ErrorGif} alt="" className="mt-3" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h1 className="modal-heading mb-3">
          Make the most out of your{" "}
          <span className="secondary-text">Angelflow </span>experience.
        </h1>
        <p className="modal-desc mt-0">
          {role?.length < 2
            ? "Please add more details to your profile!"
            : currentRole === "Investor"
              ? "Please add more details to your investor profile!"
              : currentRole === "Startup"
                ? "Please add more details to your startup profile!"
                : "Please add more details to your profile!"}
        </p>
      </Modal.Body>

      <div className="modal-foot d-flex justify-content-between gap-3 align-items-center">
        <button className="view-button mt-0" onClick={onSkip}>
          Skip
        </button>
        <button
          className="primary-btn modal-btn mt-0"
          onClick={() =>
            currentRole === "Investor"
              ? navigate("/investor-edit-profile")
              : navigate("/startup-edit-profile")
          }
        >
          Edit Profile
        </button>
      </div>
    </Modal>
  );
};

export default ProfileSetupNudgeModal;

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../../features/auth/layouts/AuthLayout";
import SignUp from "../../features/auth/components/SignUp";

const SignUpPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const userId = localStorage.getItem("userId");
    const role = localStorage.getItem("userRole");
    window.scrollTo({ top: 0, behavior: "smooth" });

    if (role || userId) navigate("/home");
  }, []);

  return (
    <AuthLayout header="SignUp" title="Create Your Account" description="Select method to registration">
      <SignUp />
    </AuthLayout>
  );
};

export default SignUpPage;

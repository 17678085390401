import { Form, Input, Flex, Button, Checkbox, Typography, Divider } from 'antd';
import { Link } from 'react-router-dom';
import { useLoginForm } from '../hooks/useLoginForm';
import SocialLinks from './SocialLinks';
import { LoginDto } from '../../../api/services/auth/Auth.dto';
import CaptchaCheckbox from './CaptchaCheckbox';

const Login = () => {
  const { handleLogin, loading } = useLoginForm();

  return (
    <Form size="large" layout="vertical" onFinish={handleLogin}>
      <SocialLinks />

      <Divider plain>
        <Typography.Paragraph className="tw-mb-0 tw-text-sm tw-text-text-primary">
          Or continue with email
        </Typography.Paragraph>
      </Divider>

      <Form.Item<LoginDto>
        label="Email Address"
        name="email"
        rules={[
          { required: true, message: 'Please enter your email address' },
          { type: 'email', message: 'Please enter a valid email address' },
        ]}
      >
        <Input placeholder="Enter your email address" />
      </Form.Item>

      <Form.Item<LoginDto>
        label="Password"
        name="password"
        rules={[{ required: true, message: 'Please enter your password' }]}
      >
        <Input.Password placeholder="Enter your password" />
      </Form.Item>

      <Flex className="tw-mt-4" align="center" justify="space-between">
        <Flex gap={2} align="center">
          <Form.Item<LoginDto> name="rememberMe" noStyle>
            <Checkbox>
              <Typography.Text className="tw-mb-0 tw-text-base tw-font-medium">Remember me</Typography.Text>
            </Checkbox>
          </Form.Item>
        </Flex>
        <Link to="/reset-password" className="tw-text-base tw-font-normal">
          Forgot password?
        </Link>
      </Flex>

      <Form.Item>
        <Button type="primary" loading={loading} className="tw-mt-8 tw-w-full" htmlType="submit" size="large">
          Login
        </Button>
      </Form.Item>

      <Flex className="tw-mt-4" vertical align="center" justify="center">
        <Form.Item<LoginDto>
          name="captcha"
          rules={[{ required: true, message: 'Please verify that you are not a robot' }]}
        >
          <CaptchaCheckbox />
        </Form.Item>
      </Flex>

      <p className="go-signin">
        Don't have an account yet?
        <Link to="/signup" className="secondary-text">
          &nbsp;Sign up today
        </Link>
      </p>
    </Form>
  );
};

export default Login;

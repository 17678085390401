import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../../features/auth/layouts/AuthLayout";
import Login from "../../features/auth/components/Login";

const LoginPage = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    const timer = setTimeout(() => {
      const role = localStorage.getItem("userRole");
      if (role && role !== "null") navigate("/home");
    }, 2000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <AuthLayout header="Login" title="Welcome Back" description="Welcome back, select method to login">
      <Login />
    </AuthLayout>
  );
};

export default LoginPage;

import React, { useState } from "react";
import { Container } from "react-bootstrap";
import AngelLogo from "./AngelLogo";
import { RiMenu3Fill } from "react-icons/ri";
import ProfileSwitcher from "./ProfileSwitcher";
import { RiFeedbackLine } from "react-icons/ri";
import PostModal from "./PostModal";
import FeedbackModal from "./feedbackModal";

const Header = ({ toggleSidebar }) => {
  const [showPostModal, setShowPostModal] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  const handleFeedbackModalToggle = () => {
    setShowFeedbackModal(!showFeedbackModal);
  };


  return (
    <Container
      fluid
      className=" header d-flex flex-row align-items-center justify-content-between "
    >
      <div className="menu-header d-flex align-items-center gap-3 flex-row">
        <div className="menu-opener" onClick={toggleSidebar}>
          <RiMenu3Fill className="opener" />
        </div>
        <AngelLogo className="logo" type="home" />
      </div>
      <div className="switch-profile">
        <ProfileSwitcher />
      </div>
      <div className="search-profile d-flex align-items-center gap-4">

        <PostModal show={showPostModal} onClose={() => setShowPostModal(false)} />


        <div className="notification d-flex align-items-center justify-content-center"
          onClick={handleFeedbackModalToggle}>
          <RiFeedbackLine className="notification-bell" />
        </div>

        <FeedbackModal
          show={showFeedbackModal}
          setShowFeedbackModal={setShowFeedbackModal}
          onClose={() => setShowFeedbackModal(false)} />

        <ProfileSwitcher />
      </div>
    </Container>
  );
};

export default Header;

import React from "react";
import { dummyProfile } from "../utils/images";
import { useNavigate } from "react-router-dom";

const FeedsProfileCard = ({ data, type }) => {
  const navigate = useNavigate();
  return (
    <div className="d-flex gap-4">
      <div className="user-profile">
        <img
          src={
            type === "startup"
              ? data?.profilePhoto && data.profilePhoto !== ""
                ? data.profilePhoto
                : dummyProfile
              : data?.profilePhoto && data.profilePhoto !== ""
                ? data.profilePhoto
                : dummyProfile
          }
          alt="user-img"
        />
      </div>
      <div className="profiles-content w-100">
        <h1 className="profiles-heading">
          {data?.companyName
            ? data?.companyName
            : data?.firstName
              ? data?.firstName + " " + data?.lastName
              : "No Name"}
        </h1>
        <p className="profiles-desc mb-0">
          {data?.about
            ? data.about.length > 49
              ? data.about.slice(0, 49) + "..."
              : data?.about
            : data?.aboutCompany
              ? data.aboutCompany.length > 49
                ? data.aboutCompany.slice(0, 49) + "..."
                : data?.aboutCompany
              : ""}
        </p>
        <div className="d-flex justify-content-end mt-2">
          <button
            className=" transparent-btn view-btn"
            onClick={() => {
              if (data?.companyName)
                navigate(`/startup/${data?.handle}`, {
                  state: { data: data?.id },
                });
              else {
                navigate(`/investor/${data?.handle}`, {
                  state: { data: data?.id },
                });
              }
            }}
          >
            View Profile
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeedsProfileCard;

import ReCAPTCHA from "react-google-recaptcha";

const RECAPTCHA_SITE_KEY = '6LfzAtQpAAAAAJt5Y0jYtnK42q11QF01DAwNgqQB';

interface Props {
  onChange?: (token: string | null) => void;
}

const CaptchaCheckbox = ({ onChange }: Props) => {
  const handleChange = (token: string | null) => {
    onChange?.(token);
  }
  
  return (
    <ReCAPTCHA
      sitekey={RECAPTCHA_SITE_KEY}
      onChange={handleChange}
    />
  );
};

export default CaptchaCheckbox;

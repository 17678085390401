import type { OverrideToken } from 'antd/es/theme/interface';
import { COLOR } from '../constants';

export const INPUT_NUMBER: OverrideToken['InputNumber'] = {
  activeShadow: `0 0 0 0`,
  borderRadius: 12,
  borderRadiusLG: 12,
  borderRadiusSM: 12,
  colorBorder: COLOR.border.gray,
  colorIcon: COLOR.icon.primary,
  colorPrimary: COLOR.text.primary,
  colorPrimaryHover: COLOR.text.primary,
  colorText: COLOR.text.primary,
  colorTextPlaceholder: COLOR.text.placeholder,
  controlHeight: 44,
  controlHeightLG: 60,
  controlHeightSM: 40,
  fontSize: 24,
  fontSizeLG: 24,
  fontSizeSM: 16,
  fontWeightStrong: 500,
};

import React from "react";
import { Container } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { BsThreeDotsVertical } from "react-icons/bs";
import { DragDrop } from "../utils/images/index";

const ImageUploader = ({
  name,
  handleImageChange,
  previewImage,
  setPreviewImage,
  handleDragOver,
  handleDragEnter,
  handleDragLeave,
  handleDrop,
  handleRemove,
}) => {
  return (
    <div
      className="uploader-cover-photo"
      // style={{
      //   backgroundImage: `url(${previewImage ? previewImage : ""})`,
      // }}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {" "}
      <Container fluid className="cover-container p-0">
        {/* <>
          <img
            src={previewImage}
            alt="Drag and drop icon"
            className="edit-image"
          />
        </> */}
        {previewImage && (
          <Dropdown className="custom-dropdown d-flex justify-content-end">
            <Dropdown.Toggle>
              <BsThreeDotsVertical className="option-svg" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item eventKey="option2" onClick={() => handleRemove()}>
                Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
        <div className="drag-content d-flex justify-content-center align-items-center mt-2 flex-column">
          <label htmlFor="coverPhotoInput" className="cover-label text-center">
            <input
              type="file"
              id="coverPhotoInput"
              accept="image/*"
              name={name}
              style={{ display: "none" }}
              onChange={(e) => handleImageChange(e)}
            />

            {previewImage ? (
              <img
                src={previewImage}
                alt="Drag and drop icon"
                className="edit-image"
              />
            ) : (
              <div className="mt-5">
                <img src={DragDrop} alt="dummy" className="mt-5 mb-2" />
                <p className="desc-cover">
                  Drop your image here, or{" "}
                  <span className="secondary-text browse-text">browse</span>
                </p>
              </div>
            )}

            {/* {!previewImage ? ( */}

            {/* ) : (
              <>
                <div className="mb-1 mt-3"></div>
              </>
            )} */}
          </label>
        </div>
      </Container>
    </div>
  );
};

export default ImageUploader;

import React, { useState } from 'react'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Spinner } from 'react-bootstrap';

const PdfDownload = ({ labelText, type, formValues }) => {

    const [loading, setLoading] = useState(false);
    const generatePDF = () => {
        setLoading(true);
        const section = document.getElementById('section');

        html2canvas(section, { useCORS: true }).then(canvas => {
            const pdf = new jsPDF();
            const imgWidth = pdf.internal.pageSize.getWidth() - 20;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            const margin = 5;

            pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 10, margin, imgWidth, imgHeight);

            if (formValues?.companyName) {
                pdf.save(`${formValues.companyName}-profile.pdf`);
            } else if (formValues?.firstName) {
                pdf.save(`${formValues.firstName}-profile.pdf`);
            } else {
                pdf.save("profile.pdf");
            }

            setLoading(false);
        }).catch(error => {
            console.error('Error generating PDF:', error);
            setLoading(false);
        });
    };
    return (
        <button
            className={type ? type : "primary-btn follow-btn d-flex align-items-center justify-content-center gap-1"}
            onClick={generatePDF}
        >
            {labelText}
            {loading ? (
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
            ) : (
                <></>
            )}
        </button>
    )
}

export default PdfDownload
import React from "react";

const FormLabel = ({
  className,
  labelText,
  htmlfor,
  required,
  helperText,
}) => {
  return (
    <label htmlFor={htmlfor} className={`${className}${helperText ? ' d-flex': ""}`}>
      {labelText}
      {required && <span className="aestaric">*</span>}
      {helperText && (
        <span className="note-text">
          {helperText}
        </span>
      )}
    </label>
  );
};

export default FormLabel;

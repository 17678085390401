import { useEffect } from "react";
import { useLocation } from "react-router-dom";

interface PageTitleProps {
  title?: string;
}

const PageTitle: React.FC<PageTitleProps> = ({ title }) => {
  const { pathname } = useLocation();
  useEffect(() => {
    document.title = !title ? 'Angelflow' : `Angelflow | ${title}`;
    
    return () => {
      document.title = 'Angelflow';
    }
  }, [title, pathname]);
  
  return null;
}

export default PageTitle;
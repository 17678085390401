import React, { useState, useEffect, useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SelectedCategories from "../components/SelectedCategories";
import UserDetails from "../components/UserDetails";
import ProfileUpload from "../components/ProfileUpload";
import CoverImage from "../components/CoverImage";
import ProfileRatingsSection from "../components/ProfileRatingsSection";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import Api from "../services/api";
import { toast } from "react-toastify";
import { formatDate } from "../helpers";
import { DummyImage, CoverPhoto } from "../utils/images";
import FeedsCard from "../components/FeedsCard";
import Sidebar from "../components/Sidebar";
import { BsCopy } from "react-icons/bs";
import { RoleContext } from "../context/currentRole";
import PdfDownload from "../components/pdfDownload";
import { config } from "../configs";
import PageTitle from "../components/PageTitle";

const StartupProfileView = () => {
  const { currentRole, setCurrentRole } = useContext(RoleContext);
  const token = localStorage.getItem("token");
  const role = localStorage?.getItem("currentRole");
  const [newsFeed, setNewsFeed] = useState([]);
 

  const [formValues, setFormValues] = useState({
    companyName: "",
    companyEmail: "",
    companyPhoneNumber: "",
    companyAddress: "",
    interestCategories: "",
    companyProfile: "",
    aboutCompany: "",
    city: "",
    country: "",
    dateOfEstablishment: "",
    companyWebsite: "",
    coverPhoto: "",
    founder: "",
    profilePhoto: "",
    linkedInLink: "",
    instagramLink: "",
    state: "",
    handle: "",
  });

  const getStartupProfile = async () => {
    try {
      const response = await Api.getStartupProfile(token);
      if (response.success) {
        if (response?.data?.newsFeed) {
          setNewsFeed(response?.data?.newsFeed);
        }


        let profileImage = response?.data?.profilePhoto || null;
        let coverImage = response?.data?.coverPhoto || null;

        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          coverPhoto: coverImage,
          profilePhoto: profileImage,
          companyEmail: response?.data?.companyEmail || "",
          companyPhoneNumber: response?.data?.companyPhoneNumber || "",
          country: response?.data?.country || "",
          city: response?.data?.city || "",
          companyAddress: response?.data?.companyAddress || "",
          interestCategories: response?.data?.interestCategories || [],
          aboutCompany: response?.data?.aboutCompany || "",
          companyName: response?.data?.companyName || "",
          companyProfile: response?.data?.companyProfile || "",
          dateOfEstablishment: response?.data?.dateOfEstablishment || "",
          companyWebsite: response?.data?.companyWebsite || "",
          linkedInLink: response?.data?.socialLinks?.linkedInLink || "",
          instagramLink: response?.data?.socialLinks?.instagramLink || "",
          founder: response?.data?.founder || "",
          socialLinks:
            {
              linkedIn: response?.data?.socialLinks?.linkedInLink,
              websiteUrl: response?.data?.companyWebsite ?? "",
            } || {},
          state: response?.data?.state || "",
          handle: response?.data?.handle || "",
        }));
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleCopy = () => {
    if (formValues?.handle) {
      navigator.clipboard
        .writeText(`${config?.AppBaseURL}/startup/${formValues?.handle}`)
        .then(() => {
          toast.success("Profile URL copied!");
        })
        .catch((error) => {
          console.error("Error copying URL: ", error);
        });
    } else {
      toast.error("Set the profile URL first in the edit profile section.");
    }
  };

  useEffect(() => {
    getStartupProfile();
    setCurrentRole(role ? role : currentRole);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <Sidebar>
      <PageTitle title="Company Profile" />
      <div className="main-content dashboard-main">
        <div id="section" className="dash-content">
          <CoverImage
            src={formValues?.coverPhoto ?? CoverPhoto}
            type="startup-profile"
          />
          <Container fluid className="public-profile">
            <Row className="bordered-profiles">
              <Col lg={7} md={6} xs={12}>
                <div className="d-flex  brand-details">
                  <ProfileUpload
                    src={formValues?.profilePhoto ?? DummyImage}
                  />
                  <UserDetails formValues={formValues} type="startup" />
                </div>
                <ProfileRatingsSection role={"startup"} formValues={formValues} />
              </Col>
              <Col lg={5} md={6} xs={12} className="p-0">
                <div className="investor-pager d-flex align-items-center gap-3 justify-content-end mt-4 ">
                  <Link
                    to="/startup-edit-profile"
                    className="investor-link w-100 d-flex justify-content-end"
                  >
                    <button className="view-button follow-btn mt-0 w-100">
                      Edit Profile
                    </button>
                  </Link>
                  <PdfDownload labelText="Download" formValues={formValues} />
                  <BsCopy className="share-option" onClick={handleCopy} />
                </div>
                {formValues?.interestCategories?.length > 0 && (
                  <div className="interested-categories interested-profile">
                    <h2 className="category-heading">Categories</h2>
                    <SelectedCategories
                      categories={formValues?.interestCategories}
                    />
                  </div>
                )}
              </Col>
            </Row>
          </Container>

          <Container fluid className="footer-container">
            <Row className="mt-3 about-bordered">
              <Col lg={12} md={12} xs={12}>
                <h1 className="about-heading mt-3">About Company</h1>
                <p className="about-desc mt-3">
                  {formValues?.aboutCompany ? formValues?.aboutCompany : "..."}
                </p>
              </Col>
            </Row>
          </Container>

          <Container fluid className="footer-container">
            <Row className="mt-4 joined-bordered">
              <Col lg={3} md={6} xs={12}>
                <div className="joined-table d-flex flex-column gap-4">
                  <span className="joined-headings">Year Founded</span>
                  <span className="joined-desc">
                    {formValues?.dateOfEstablishment
                      ? formatDate(formValues?.dateOfEstablishment)
                      : "..."}
                  </span>
                </div>
              </Col>
              <Col lg={3} md={6} xs={12}>
                <div className="joined-table d-flex flex-column gap-4">
                  <span className="joined-headings">City</span>
                  <span className="joined-desc">
                    {" "}
                    {formValues?.city ? formValues?.city : `...`}
                  </span>
                </div>
              </Col>
              <Col lg={3} md={6} xs={12}>
                <div className="joined-table d-flex flex-column gap-4">
                  <span className="joined-headings">State</span>
                  <span className="joined-desc">
                    {" "}
                    {formValues?.state ? formValues?.state : `...`}
                  </span>
                </div>
              </Col>
              <Col lg={3} md={6} xs={12}>
                <div className="joined-table d-flex flex-column gap-4">
                  <span className="joined-headings">Country</span>
                  <span className="joined-desc">
                    {" "}
                    {formValues?.country ? formValues?.country : `...`}
                  </span>
                </div>
              </Col>
            </Row>
          </Container>
          <Container fluid className="footer-container">
            <Row>
              <h1 className="profile-heading mb-0 mt-4">
                Startup News/Updates
                <span className="secondary-text slash-trending">_</span>
              </h1>
              <div className="news-feeds mt-2 mb-4 custom-scrollbar mb-4">
                {newsFeed?.length > 0 ? (
                  newsFeed?.map((newsfeed, index) => (
                    <FeedsCard
                      newsfeed={newsfeed}
                      text="startup-feed feeds-post d-flex justify-content-start mt-2 gap-3"
                    />
                  ))
                ) : (
                  <div className="d-flex justify-content-center align-items-center mt-5 mb-5 ">
                    <p className="joined-headings">No updates found</p>
                  </div>
                )}
              </div>
            </Row>
          </Container>
        </div>
        <div className="mt-5">
          <Footer className="simple-container" />
        </div>
      </div>
    </Sidebar>
  );
};

export default StartupProfileView;

import Chat from "../../views/chat";
import Feeds from "../../views/feeds";
import ProfileSetup from "../../views/ProfileSetup";
import StartupPublicView from "../../views/startupPublicView";
import StartupProfileView from "../../views/startupProfileView";
import StartupEditProfile from "../../views/startupEditProfile";
import InvestorEditProfile from "../../views/investorEditProfile";
import InvestorPublicView from "../../views/investorPublicView";
import InvestorProfileView from "../../views/investorProfileView";

import {
  home,
  chat,
  profileSetup,
  startupPublicView,
  startupProfileView,
  startupEditProfile,
  investorEditProfile,
  investorPublicView,
  investorProfileView,
  investors,
  startups,
  followers,
  userSettings,
  changePassword,
} from "../pathName";
import AllInvestors from "../../views/allInvestors";
import AllStartups from "../../views/allStartups";
import AllFollowers from "../../views/allFollowers";
import Settings from "../../views/settings";
import UpdatePassword from "../../views/updatePassword";

export const PrivateRoutes = [
  {
    title: "Profile Setup",
    component: ProfileSetup,
    path: profileSetup,
  },
  {
    title: "Home",
    component: Feeds,
    path: home,
  },
  {
    title: "Chat",
    component: Chat,
    path: chat,
  },
  {
    title: "Startup Public view",
    component: StartupPublicView,
    path: startupPublicView,
  },
  {
    title: "Startup Profile view",
    component: StartupProfileView,
    path: startupProfileView,
  },
  {
    title: "Startup Edit Profile",
    component: StartupEditProfile,
    path: startupEditProfile,
  },
  {
    title: "Investor Edit Profile",
    component: InvestorEditProfile,
    path: investorEditProfile,
  },
  {
    title: "Investor Public View",
    component: InvestorPublicView,
    path: investorPublicView,
  },
  {
    title: "Investor Profile View",
    component: InvestorProfileView,
    path: investorProfileView,
  },
  {
    title: "Investors",
    component: AllInvestors,
    path: investors,
  },
  {
    title: "Startups",
    component: AllStartups,
    path: startups,
  },
  {
    title: "Followers",
    component: AllFollowers,
    path: followers,
  },
  {
    title: "User Setting",
    component: Settings,
    path: userSettings,
  },
  {
    title: "Change Password",
    component: UpdatePassword,
    path: changePassword,
  },
];

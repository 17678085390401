import React from 'react'
import { Container } from 'react-bootstrap'
import AngelLogo from './AngelLogo';

const currentYear = new Date().getFullYear();

const Footer = ({ className }) => {
    return (
        <footer className='footer-section'>
            <Container fluid className={className ? className : 'footer-container'}>
                <div className='angelflow-shadow align-items-center d-flex align-items-center justify-content-between'>
                    <div className='angel-footer'>
                        <AngelLogo className="logo" name="angel-logos" type="home" />
                    </div>
                    <div className='d-flex justify-content-end align-items-center'>
                        <p className='footer-desc mb-0'>
                            Copyright © <a className='secondary-text' href='https://www.angelflow.vc/' target='_blank' rel='noreferrer'>Angelflow</a> {currentYear}
                        </p>
                    </div>
                </div>
            </Container>
        </footer>
    )
}

export default Footer
/**
 * Returns the container element for popups.
 *
 * @param {HTMLElement | undefined} node - The HTML element to find the container for.
 * @param {string[]} [queries=['.ant-modal', '.ant-drawer']] - The list of CSS selectors to query for the container.
 * @returns {HTMLElement} - The container element for the popup.
 */
export const getPopupContainer = (
  node: HTMLElement | undefined,
  queries = ['.ant-modal', '.ant-drawer'],
) => {
  const parent = document.querySelector(
    queries.find((e) => document.querySelector(e)) as string,
  );
  if (node && parent?.contains(node)) return node.parentNode as HTMLElement;
  return document.body;
};

import React, { useCallback, useEffect, useState } from "react";
import MainRoute from "./routes";
import { ToastContainer, toast } from "react-toastify";

const App = () => {
  const token = localStorage.getItem("token");

  const [isSessionExpired, setIsSessionExpired] = useState(false);

  const handleLogout = useCallback(() => {
    if (token) {
      localStorage.clear();
      window.location.href = "/";
      toast.error("Your session has expired. Please login again");
    }
  }, [token]);

  const startInactivityTimer = () => {
    return setTimeout(() => {
      setIsSessionExpired(true);
    }, 3600000);
  };

  useEffect(() => {
    let inactivityTimer = startInactivityTimer();

    const resetTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = startInactivityTimer();
      setIsSessionExpired(false);
    };

    const handleActivity = () => {
      resetTimer();

      if (isSessionExpired) {
        handleLogout();
      }
    };

    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keypress", handleActivity);

    return () => {
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keypress", handleActivity);
      clearTimeout(inactivityTimer);
    };
  }, [isSessionExpired, handleLogout]);

  return (
    <div data-testid="app">
      <ToastContainer />
      <MainRoute />
    </div>
  );
};

export default App;

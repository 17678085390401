import React, { useContext } from "react";
import { Dropdown } from "react-bootstrap";
import { RoleContext } from "../context/currentRole";
import { RiArrowDownSLine } from "react-icons/ri";
import { dummyProfile } from "../utils/images";
import { Link, useNavigate } from "react-router-dom";

const ProfileSwitcher = () => {
  const navigate = useNavigate();
  const roleString = localStorage.getItem("userRole");
  const roles = JSON.parse(roleString) || [];
  const { currentRole, setCurrentRole } = useContext(RoleContext);
  const userName = localStorage?.getItem("userName");
  const profilePhoto = localStorage?.getItem("profilePhoto");
  const companyName = localStorage?.getItem("companyName");
  const profileFlag = localStorage?.getItem("profileFlag");
  const companyProfilePhoto = localStorage?.getItem("companyProfilePhoto");
  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  const handleRole = () => {
    setCurrentRole(currentRole === "Startup" ? "Investor" : "Startup");
    localStorage.setItem(
      "currentRole",
      currentRole === "Startup" ? "Investor" : "Startup"
    );
  };

  return (
    <div className="profile-dropdown d-flex flex-row gap-2 align-items-center">
      <div className="profilep-img">
        <img
          src={
            currentRole === "Investor" &&
            profilePhoto != "" &&
            profilePhoto != "null" &&
            profilePhoto
              ? profilePhoto
              : currentRole === "Investor" &&
                (profilePhoto == "null" ||
                  profilePhoto == "" ||
                  profilePhoto == "undefined")
              ? dummyProfile
              : currentRole === "Startup" &&
                (companyProfilePhoto == "null" ||
                  companyProfilePhoto == "undefined")
              ? dummyProfile
              : currentRole === "Startup" && companyProfilePhoto
              ? companyProfilePhoto
              : dummyProfile
          }
          alt=""
        />
      </div>
      <div className="owner-profile d-flex  flex-column gap-1">
        <h1 className="owner-name mb-0">
          {currentRole === "Investor" && userName && userName != "null"
            ? userName.length > 9
              ? userName?.substring(0, 9) + "..."
              : userName
            : currentRole === "Startup" && companyName && companyName != "null"
            ? companyName.length > 9
              ? companyName?.substring(0, 9) + "..."
              : companyName
            : ""}
        </h1>
        <Dropdown className="settings-dropdown">
          <Dropdown.Toggle
            id="dropdown-basic"
            className="d-flex align-items-center gap-1"
          >
            {currentRole}
            <RiArrowDownSLine className="arrow-down" />
          </Dropdown.Toggle>

          <Dropdown.Menu className="profile-dropdown">
            {/* {roles.includes("investor") && roles.includes("startup") ? ( */}
            <Link className="profile-item" onClick={() => handleRole()}>
              <Dropdown.Item
                onClick={() =>
                  profileFlag == 2 && currentRole != "Startup"
                    ? navigate("/startup-edit-profile")
                    : profileFlag == 3 && currentRole != "Investor"
                    ? navigate("/investor-edit-profile")
                    : navigate("/home")
                }
              >
                {profileFlag == 2 && currentRole === "Startup"
                  ? "Switch to Investor"
                  : profileFlag == 3 && currentRole === "Investor"
                  ? "Switch to Startup"
                  : profileFlag == 2
                  ? "Create Startup Profile"
                  : profileFlag == 3
                  ? "Create Investor Profile"
                  : profileFlag == 1 && currentRole === "Startup"
                  ? "Switch to Investor"
                  : "Switch to Startup"}
              </Dropdown.Item>
            </Link>
            {/* ) : (
              <></>
            )} */}
            <Link
              className="profile-item"
              onClick={() =>
                navigate(
                  currentRole === "Startup"
                    ? "/startup-profile-view"
                    : "/investor-profile-view"
                )
              }
            >
              <Dropdown.Item
                disabled={
                  profileFlag == 2 &&
                  roles?.length < 2 &&
                  roles?.includes("investor") &&
                  currentRole === "Investor"
                    ? false
                    : profileFlag == 2 &&
                      roles?.length < 2 &&
                      roles?.includes("investor") &&
                      currentRole === "Startup"
                    ? true
                    : profileFlag == 3 &&
                      roles?.length < 2 &&
                      roles?.includes("startup") &&
                      currentRole === "Startup"
                    ? false
                    : profileFlag == 3 &&
                      roles?.length < 2 &&
                      roles?.includes("startup") &&
                      currentRole === "Investor"
                    ? true
                    : profileFlag == 1
                    ? false
                    : true
                }
              >
                Profile View
              </Dropdown.Item>
            </Link>
            <Link
              className="profile-item"
              onClick={() =>
                navigate(
                  currentRole === "Startup"
                    ? "/startup-edit-profile"
                    : "/investor-edit-profile"
                )
              }
            >
              <Dropdown.Item
                disabled={
                  profileFlag == 2 &&
                  roles?.length < 2 &&
                  roles?.includes("investor") &&
                  currentRole === "Investor"
                    ? false
                    : profileFlag == 2 &&
                      roles?.length < 2 &&
                      roles?.includes("investor") &&
                      currentRole === "Startup"
                    ? true
                    : profileFlag == 3 &&
                      roles?.length < 2 &&
                      roles?.includes("startup") &&
                      currentRole === "Startup"
                    ? false
                    : profileFlag == 3 &&
                      roles?.length < 2 &&
                      roles?.includes("startup") &&
                      currentRole === "Investor"
                    ? true
                    : profileFlag == 1
                    ? false
                    : true
                }
              >
                Edit Profile
              </Dropdown.Item>
            </Link>
            <Link
              className="profile-item"
              onClick={() => navigate("/change-password")}
            >
              <Dropdown.Item>Update Password</Dropdown.Item>
            </Link>
            <Link className="profile-item">
              <Dropdown.Item onClick={() => handleLogout()}>
                Logout
              </Dropdown.Item>
            </Link>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default ProfileSwitcher;

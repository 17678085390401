import type { OverrideToken } from 'antd/es/theme/interface';
import { COLOR } from '../constants';

export const BUTTON: OverrideToken['Button'] = {
  borderColorDisabled: COLOR.border.disabled,
  borderRadius: 12,
  borderRadiusLG: 12,
  borderRadiusSM: 12,
  colorLink: COLOR.system.information,
  colorText: COLOR.text.primary,
  colorPrimaryText: COLOR.text.white,
  colorTextBase: COLOR.text.primary,
  colorTextLightSolid: COLOR.text.body,
  controlHeight: 50,
  controlHeightLG: 56,
  controlHeightSM: 38,
  defaultBg: COLOR.background.primary,
  defaultBorderColor: COLOR.border.brand,
  defaultColor: COLOR.text.primary,
  defaultHoverBg: COLOR.primary[500],
  defaultHoverBorderColor: 'transparent',
  groupBorderColor: 'unset',
  linkHoverBg: 'tranparent',
  paddingBlock: 8,
  paddingInline: 12,
  primaryColor: COLOR.text.white,
  primaryShadow: 'unset',
  colorPrimary: COLOR.background.primary,
};

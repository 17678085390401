import React, { StrictMode } from "react";
import ReactGA from "react-ga4";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./theme/styles/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

import { ConfigProvider } from 'antd';
import enUS from 'antd/es/locale/en_US';
import { formConfig } from './theme/form.ts';
import { theme } from './theme/index.ts';
import { getPopupContainer } from './theme/utils.ts';

ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID)

const root = createRoot(document.getElementById("root"));

// StrictMode is for highlighting issues during development
root.render(
  <StrictMode>
    <ConfigProvider
      componentSize="middle"
      getPopupContainer={getPopupContainer}
      form={formConfig}
      locale={enUS}
      theme={theme}
    >
      <App />
    </ConfigProvider>
  </StrictMode>
);

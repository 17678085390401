import React, { useState, useEffect, useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SelectedCategories from "../components/SelectedCategories";
import UserDetails from "../components/UserDetails";
import ProfileUpload from "../components/ProfileUpload";
import CoverImage from "../components/CoverImage";
import ProfileRatingsSection from "../components/ProfileRatingsSection";
import Footer from "../components/Footer";
import Api from "../services/api";
import { toast } from "react-toastify";
import { formatDate } from "../helpers";
import {
  DummyImage,
  CoverPhoto,
  PrivateProfile,
  ErrorImage,
} from "../utils/images";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RoleContext } from "../context/currentRole";
import { config } from "../configs";
import FeedsCard from "../components/FeedsCard";
import Sidebar from "../components/Sidebar";
import { BsCopy } from "react-icons/bs";
import LoaderGif from "../components/LoaderGif";
import PdfDownload from "../components/pdfDownload";
import PageTitle from "../components/PageTitle";

const StartupPublicView = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { id } = useParams();
  const role = localStorage?.getItem("currentRole");
  const { currentRole, setCurrentRole } = useContext(RoleContext);
  const [profileActive, setProfileActive] = useState(false);
  const [newsFeed, setNewsFeed] = useState([]);
  const token = localStorage.getItem("token");
  const [loader, setLoader] = useState(true);  

  const [formValues, setFormValues] = useState({
    companyName: "",
    companyEmail: "",
    companyPhoneNumber: "",
    companyAddress: "",
    interestCategories: "",
    companyProfile: "",
    aboutCompany: "",
    city: "",
    country: "",
    dateOfEstablishment: "",
    companyWebsite: "",
    coverPhoto: "",
    founder: "",
    profilePhoto: "",
    linkedInLink: "",
    instagramLink: "",
    state: "",
    isFollowing: false,
    isPrivateProfile: false,
    handle: "",
  });

  const handleCopy = () => {
    if (formValues?.handle) {
      navigator.clipboard
        .writeText(`${config?.AppBaseURL}/startup/${formValues?.handle}`)
        .then(() => {
          toast.success("Profile URL copied!");
        })
        .catch((error) => {
          console.error("Error copying URL: ", error);
        });
    } else {
      toast.error("Set the profile URL first in the edit profile section.");
    }
  };

  const getStartupProfile = async () => {
    try {
      const response = await Api.getStartupProfile(
        token,
        !id ? "" : id,
        currentRole === "Investor" ? "investor" : "startup"
      );

      if (response.success) {
        if (response?.data?.newsFeed) {
          setNewsFeed(response?.data?.newsFeed);
        }

        let profileImage = response?.data?.profilePhoto || null;
        let coverImage = response?.data?.coverPhoto || null;

        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          coverPhoto: coverImage,
          profilePhoto: profileImage,
          companyEmail: response?.data?.companyEmail || "",
          companyPhoneNumber: response?.data?.companyPhoneNumber || "",
          country: response?.data?.country || "",
          city: response?.data?.city || "",
          companyAddress: response?.data?.companyAddress || "",
          interestCategories: response?.data?.interestCategories || [],
          aboutCompany: response?.data?.aboutCompany || "",
          companyName: response?.data?.companyName || "",
          companyProfile: response?.data?.companyProfile || "",
          dateOfEstablishment: response?.data?.dateOfEstablishment || "",
          companyWebsite: response?.data?.companyWebsite || "",
          linkedInLink: response?.data?.socialLinks?.linkedInLink || "",
          instagramLink: response?.data?.socialLinks?.instagramLink || "",
          founder: response?.data?.founder || "",
          state: response?.data?.state || "",
          socialLinks:
            {
              linkedIn: response?.data?.socialLinks?.linkedInLink,
              websiteUrl: response?.data?.companyWebsite ?? "",
            } || {},
          isFollowing: response?.data?.isFollowing || false,
          isPrivateProfile: response?.data?.isPrivateProfile || false,
          handle: response?.data?.handle || "",
        }));

        setProfileActive(true);
      }
    } catch (error) {
      toast.error(error?.data?.message);
    } finally{
      setLoader(false);
    }
  };

  const handleFollow = async (e) => {
    e.preventDefault();
    try {
      const response = await Api.followProfile(
        {
          followingId: location?.state?.data,
          type: "startup",
          followerRole: currentRole === "Investor" ? "investor" : "startup",
        },
        token
      );
      if (response.success) {
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          isFollowing: true,
        }));
      }
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };

  const handleUnFollow = async (e) => {
    e.preventDefault();
    try {
      const response = await Api.unFollowProfile(
        {
          followingId: location?.state?.data,
          type: "startup",
          followerRole: currentRole === "Investor" ? "investor" : "startup",
        },
        token
      );
      if (response.success) {
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          isFollowing: false,
        }));
      }
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getStartupProfile();
    setCurrentRole(role);
  }, [id]);

  return (
    <>
      {loader ? (
        <LoaderGif />
      ) : profileActive ? (
        <Sidebar>
          <PageTitle title="Profile" />
          <div className="main-content dashboard-main">
            <div id="section" className="dash-content">
              <CoverImage
                src={formValues?.coverPhoto ?? CoverPhoto}
                type="startup-profile"
              />
              <Container fluid className="public-profile">
                <Row className="bordered-profiles">
                  <Col lg={7} md={6} xs={12}>
                    <div className="d-flex  brand-details">
                      <ProfileUpload
                        src={formValues?.profilePhoto ?? DummyImage}
                      />
                      <UserDetails formValues={formValues} />
                    </div>
                    {!formValues?.isPrivateProfile && (
                      <ProfileRatingsSection role={"startup"} formValues={formValues} />
                    )}
                  </Col>
                  <Col lg={5} md={6} xs={12} className="p-0">
                    <div className="investor-pager  d-flex align-items-center gap-3 justify-content-end mt-4 ">
                      {!formValues?.isPrivateProfile && (
                        <PdfDownload
                          type="view-button follow-btn capture-btn mt-0 d-flex align-items-center justify-content-center gap-1"
                          labelText="Download Page as PDF"
                          formValues={formValues}
                        />
                      )}
                      <button
                        className="primary-btn follow-button"
                        onClick={(e) =>
                          !formValues?.isFollowing
                            ? handleFollow(e)
                            : handleUnFollow(e)
                        }
                      >
                        {formValues?.isFollowing ? "Following" : "Follow"}
                      </button>
                      {!formValues?.isPrivateProfile && (
                        <BsCopy
                          className="share-options"
                          onClick={handleCopy}
                        />
                      )}
                    </div>

                    {!formValues?.isPrivateProfile &&
                      formValues?.interestCategories?.length > 0 && (
                      <div className="interested-categories">
                        <h2 className="category-heading">Categories</h2>
                        <SelectedCategories
                          categories={formValues?.interestCategories}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </Container>

              {!formValues?.isPrivateProfile ? (
                <>
                  <Container fluid className="public-profile">
                    <Row className="mt-3 about-bordered">
                      <Col lg={12} md={12} xs={12}>
                        <h1 className="about-heading mt-3">About Company</h1>
                        <p className="about-desc mt-3">
                          {formValues?.aboutCompany
                            ? formValues?.aboutCompany
                            : `...`}
                        </p>
                      </Col>
                    </Row>
                  </Container>

                  <Container fluid className="footer-container">
                    <Row className="mt-4 joined-bordered">
                      <Col lg={3} md={6} xs={12}>
                        <div className="joined-table d-flex flex-column gap-4">
                          <span className="joined-headings">Year Founded</span>
                          <span className="joined-desc">
                            {formValues?.dateOfEstablishment
                              ? formatDate(formValues?.dateOfEstablishment)
                              : "..."}
                          </span>
                        </div>
                      </Col>
                      <Col lg={3} md={6} xs={12}>
                        <div className="joined-table d-flex flex-column gap-4">
                          <span className="joined-headings">City</span>
                          <span className="joined-desc">
                            {formValues?.city ? formValues?.city : `...`}
                          </span>
                        </div>
                      </Col>
                      <Col lg={3} md={6} xs={12}>
                        <div className="joined-table d-flex flex-column gap-4">
                          <span className="joined-headings">State</span>
                          <span className="joined-desc">
                            {formValues?.state ? formValues?.state : `...`}
                          </span>
                        </div>
                      </Col>
                      <Col lg={3} md={6} xs={12}>
                        <div className="joined-table d-flex flex-column gap-4">
                          <span className="joined-headings">Country</span>
                          <span className="joined-desc">
                            {formValues?.country ? formValues?.country : `...`}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </>
              ) : (
                <div className="d-flex flex-row gap-2 align-items-center justify-content-center mt-4">
                  <img
                    style={{ height: "auto", width: "40px" }}
                    src={PrivateProfile}
                    alt="PrivateProfile"
                  />
                  <span className="private-profile">
                    This profile is private
                  </span>
                </div>
              )}
              {!formValues?.isPrivateProfile && (
                <Container fluid className="footer-container mt-4">
                  <div className="news-section d-flex  justify-content-between align-items-center">
                    <h1 className="profile-heading mt-1 mb-0">
                      Startup News/Updates
                      <span className="secondary-text slash-trending">
                        _
                      </span>
                    </h1>
                    <div className="d-flex gap-4 mb-1">
                      <button
                        className="primary-btn feed-btn"
                        onClick={() => navigate("/home")}
                      >
                        Show All
                      </button>
                    </div>
                  </div>
                  <div className="news-feeds mt-4 mb-4 d-flex flex-column gap-4 custom-scrollbar ">
                    {newsFeed?.length > 0 ? (
                      newsFeed?.map((newsfeed, index) => (
                        <FeedsCard
                          newsfeed={newsfeed}
                          text="startup-feed feeds-post d-flex justify-content-start mt-2 gap-3"
                        />
                      ))
                    ) : (
                      <div className="d-flex justify-content-center align-items-center mt-5 mb-5">
                        No updates found
                      </div>
                    )}
                  </div>
                </Container>
              )}
            </div>
            <div className="mt-5">
              <Footer className="simple-container" />
            </div>
          </div>
        </Sidebar>
      ) : (
        <div className="main-error">
          <div className="profile-exist-image">
            <img src={ErrorImage} alt="404" />
            <div className="profile-exist mt-5 ">Error loading profile</div>
          </div>
        </div>
      )}
    </>
  );
};

export default StartupPublicView;
